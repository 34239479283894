import { Activity, Course, Creation, Group, Student, User } from '@kritik/types.generated';
import * as ActivityUtil from '@kritik/utils/activity';
import * as ActivityUtils from '@kritik/utils/activity';
import * as creationStatusUtil from '@kritik/utils/creation/status';
import { CreationScoreUtil, EvaluationScoreUtil, FeedbackScoreUtil } from '@kritik/utils/grade';
import {
  getEvaluationStage,
  getFeedbackStage,
  isFinalized,
  studentParticipatesInStageFromInstructorView,
} from '@kritik/utils/stage';
import { Profile } from 'app-types';
import CreationSummary from 'components/Creation/CreationOverview/CreationSummary';
import EvaluationTab from 'components/Creation/CreationOverview/EvaluationTab';
import FeedbackScore from 'components/Creation/CreationOverview/FeedbackTab/FeedbackScore';
import FeedbackSummary from 'components/Creation/CreationOverview/FeedbackTab/FeedbackSummary';
import DisputeLabel from 'components/Creation/Dispute/Label';
import { FlagLabel } from 'components/Creation/Flag/Label';
import StatusLabel from 'components/Creation/StatusLabel';
import InstructorGradedLabel from 'components/Creation/StatusLabel/InstructorGraded';
import LateSubmissionLabel from 'components/Creation/StatusLabel/LateCreation';
import MissedLabel from 'components/Creation/StatusLabel/Missed';
import NotGradedLabel from 'components/Creation/StatusLabel/NotGraded';
import { LIST_BY_TYPES } from 'components/CreationList/constant';
import { isListByGroup } from 'components/CreationList/util';
import GroupCard from 'components/GroupManager/GroupCard';
import ViewAsStudentModal from 'components/RosterManager/ViewAsStudentModal';
import { TranslatedText } from 'components/TranslatedText';
import { Button } from 'components/buttons';
import { PanelDisplay } from 'components/layout';
import AvatarDisplay from 'components/layout/AvatarDisplay';
import HorizontalList from 'components/layout/HorizontalList';
import TabMenu from 'components/layout/TabMenu';
import TabMenuItem from 'components/layout/TabMenu/Item';
import GradingChangeSideBox from 'containers/assignments/creations/GradingChangeSideBox';
import { useGetRankLevelChange } from 'hooks/useRankLevel';
import { localize } from 'locales';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAvgGroupScoresByCreationId } from 'redux/activityScores/actions';
import { selectCreationTableListBy } from 'redux/creationTable/select';
import { getAssignment } from 'selectors/activity';
import { getCourse } from 'selectors/course';
import { getStudentFromUser, selectCurrentStudent, selectStudentScoreHistories } from 'selectors/student';
import { getNewKS } from 'utils/student';
import { RouterProp, withRouter } from 'utils/withRouter';

const CALIBRATION_MENU_OPTIONS = [{ id: 'evaluation', title: 'Evaluation' }];

const mapStateToProps = (state: any, ownProps: any) => {
  const course = getCourse(state);
  const student =
    course.userRole === 'student' ? getStudentFromUser(state, state.selected.courseId) : selectCurrentStudent(state);

  return {
    user: state.user,
    course,
    creationTableListBy: selectCreationTableListBy(state),
    activity: getAssignment(state, state.selected.assignmentId),
    scoreHistories: selectStudentScoreHistories(state, student._id),
  };
};

type CreationOverviewProps = {
  assignment: Activity;
  submission: Creation & { group?: Group };
  student: Student & { user: User & { profile: Profile } };
  course: Course;
  user: User;
  creationTableListBy: 'Student' | 'Group';
  activity: Activity;
  scoreHistories: Student['scoreHistories'];
  getAvgGroupScoresByCreationId: any;
  router: RouterProp;
  getRankLevelChange: string;
};
type CreationOverviewState = {
  selectedView: string;
  isModalOpen: boolean;
};

const shouldShowProfGradingChangeSideBox = (assignment: any) => {
  return isFinalized({ assignment });
};

class CreationOverviewComponent extends Component<CreationOverviewProps, CreationOverviewState> {
  constructor(props) {
    super(props);
    this.state = {
      selectedView: 'creation',
      isModalOpen: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (ActivityUtil.isCalibrationActivity(this.props.assignment)) {
      this.setState({ selectedView: 'evaluation' });
    }

    if (this.isGroupView()) {
      this.props.getAvgGroupScoresByCreationId({
        activityId: this.props.assignment._id,
        creationId: this.props.submission._id,
      });
    }
  }

  componentDidUpdate(prevProps: {}) {
    if ((prevProps as any).submission._id !== this.props.submission._id) {
      const view = ActivityUtil.isCalibrationActivity(this.props.assignment) ? 'evaluation' : 'creation';

      if (this.isGroupView()) {
        this.props.getAvgGroupScoresByCreationId({
          activityId: this.props.assignment._id,
          creationId: this.props.submission._id,
        });
      }

      this.setState({
        selectedView: view,
      });
    }
  }

  displayLabels(submission: any) {
    const renderFlags = () => {
      const flags = creationStatusUtil.getNumOfCreationFlags(submission);
      if (flags === 0) {
        return null;
      }
      return (
        <FlagLabel
          status="danger"
          label={localize({ message: 'Creation.ReportedEvaluation' })}
          count={flags}
          testid="creation-table-flag-label"
        />
      );
    };

    const studentHasGraceEvaluations = this.props.assignment.graceEvaluations?.find(
      (evaluation) => evaluation.studentId === this.props.student._id
    );

    return (
      <HorizontalList
        itemList={[
          <NotGradedLabel creation={submission} activity={this.props.assignment} />,
          <InstructorGradedLabel creation={submission} activity={this.props.assignment} />,
          renderFlags(),
          <DisputeLabel creation={submission} />,
          <LateSubmissionLabel creation={submission} />,
          studentHasGraceEvaluations && (
            <StatusLabel status="warning" label={<TranslatedText i18nKey="Evaluation.GracePeriod.Label" />} />
          ),
          <MissedLabel creation={submission} activity={this.props.assignment} />,
        ]}
      />
    );
  }

  changeSelectedView(selectedView: any) {
    this.setState({ selectedView });
  }

  profGradedTab = (text: any, notGraded = false) => {
    return (
      <span>
        <i className={`fa fa-book submission-overview__tab-icon-${notGraded ? 'not-graded' : 'prof-graded'}`} />
        {text}
      </span>
    );
  };

  addedIconsTab = (text: any, notGraded, flagged = false) => {
    return (
      <span>
        {notGraded && <i className={`fa fa-book submission-overview__tab-icon-prof-graded`} />}
        {text}
        {flagged && (
          <i
            className={`fa fa-flag submission-overview__tab-icon-flagged`}
            data-testid="creation-overview-feedback-tab-flagged-evaluation"
          />
        )}
      </span>
    );
  };

  getCreationTab = () => {
    const { submission, assignment } = this.props;
    if (creationStatusUtil.isCreationNotGradedAfterEvaluation(submission, assignment)) {
      return this.profGradedTab('Creation', true);
    }
    return CreationScoreUtil.isInstructorGraded(submission) || CreationScoreUtil.isProfRevised(submission)
      ? this.profGradedTab('Creation')
      : 'Creation';
  };

  getEvaluationTab = () => {
    const { submission } = this.props;
    return EvaluationScoreUtil.isEvaluationScoredByTeacher(submission)
      ? this.profGradedTab('Evaluation')
      : 'Evaluation';
  };

  getFeedbackTab = () => {
    const { submission } = this.props;
    const numOfFlags = creationStatusUtil.getNumOfCreationFlags(submission);
    return FeedbackScoreUtil.isFeedbackScoredByTeacher(submission) || numOfFlags > 0
      ? this.addedIconsTab('Feedback', FeedbackScoreUtil.isFeedbackScoredByTeacher(submission), numOfFlags > 0)
      : 'Feedback';
  };

  getOverviewTabs = () => {
    if (ActivityUtil.isCalibrationActivity(this.props.assignment)) {
      return CALIBRATION_MENU_OPTIONS;
    }
    const options = [{ id: 'creation', title: this.getCreationTab() }];
    if (
      ActivityUtil.isPeerActivity(this.props.assignment) ||
      this.props.creationTableListBy === LIST_BY_TYPES.STUDENT
    ) {
      options.push({ id: 'evaluation', title: this.getEvaluationTab() });
    }
    if (
      (ActivityUtil.isGroupAssignment(this.props.assignment) ||
        ActivityUtil.isGroupPresentationActivity(this.props.assignment)) &&
      this.props.creationTableListBy === LIST_BY_TYPES.GROUP
    ) {
      options.push({ id: 'evaluation', title: this.getEvaluationTab() });
    }
    options.push({ id: 'feedback', title: this.getFeedbackTab() });
    return options;
  };

  isGroupView() {
    const params = new URLSearchParams(this.props.router.location.search);
    const isGroupView = params.get('groupView') === 'true';
    return isGroupView;
  }

  renderFeedbackTab() {
    const { assignment, submission, student } = this.props;
    if (ActivityUtil.isGroupPresentationActivity(assignment)) {
      const feedbackStage = getFeedbackStage(assignment);
      if (
        !studentParticipatesInStageFromInstructorView({
          stage: feedbackStage,
          studentId: (submission.student as Student)._id,
        })
      ) {
        return (
          <>
            <div className="submission-view-header">
              <h3>{localize({ message: 'Activity.Feedback' })}</h3>
            </div>
            {localize({
              message: 'Activity.GroupPresentation.Overview.Feedback.Group.NotRequired',
            })}
          </>
        );
      }
    }
    return (
      <React.Fragment>
        <FeedbackScore creation={submission} activity={assignment} groupView={this.isGroupView()} />
        <FeedbackSummary activity={assignment} creation={submission} groupView={this.isGroupView()} student={student} />
      </React.Fragment>
    );
  }

  renderViewContent() {
    const { assignment, submission } = this.props;
    if (this.state.selectedView === 'creation') {
      return <CreationSummary {...this.props} key={submission._id} />;
    }
    if (this.state.selectedView === 'evaluation') {
      if (ActivityUtil.isGroupPresentationActivity(assignment)) {
        const evaluationStage = getEvaluationStage(assignment);
        const evaluationStageParticipantsIds = evaluationStage.students
          .filter((s) => s.isParticipating)
          .map((s) => s.student);
        const groupMembersIds = (submission.group as Group).members;
        const isMemberParticipating = groupMembersIds.some((id) => evaluationStageParticipantsIds.includes(id));

        if (!isMemberParticipating) {
          return (
            <div className="kritik-form-container">
              <div className="submission-view-header">
                <h3>{localize({ message: 'Activity.Evaluation' })}</h3>
              </div>
              {localize({
                message: 'Activity.GroupPresentation.Overview.Evaluation.Group.NotRequired',
              })}
            </div>
          );
        }
      }
      return (
        <EvaluationTab
          activity={this.props.assignment}
          student={this.props.student}
          course={this.props.course}
          creation={this.props.submission}
          isGroupView={this.isGroupView()}
        />
      );
    }
    if (this.state.selectedView === 'feedback') {
      if (ActivityUtils.isPresentationActivity(this.props.activity)) {
        const evaluationStage = getEvaluationStage(this.props.activity);
        const message = localize({
          message: ActivityUtils.isGroupPresentationActivity(this.props.activity)
            ? 'Activity.GroupPresentation.Overview.Feedback.Group.NotRequired'
            : 'Activity.IndividualPresentation.Overview.Feedback.Individual.NotRequired',
        });

        if (
          !studentParticipatesInStageFromInstructorView({
            stage: evaluationStage,
            studentId: this.props.student._id,
          })
        ) {
          return (
            <div className="kritik-form-container">
              <div className="submission-view-header">
                <h3>{localize({ message: 'Activity.Feedback' })}</h3>
              </div>
              {message}
            </div>
          );
        }
      }

      return this.renderFeedbackTab();
    }
  }

  renderHeader() {
    const { submission: creation, assignment, creationTableListBy, student } = this.props;
    const shouldShowGroupProfile =
      (ActivityUtil.isGroupAssignment(assignment) ||
        ActivityUtil.isWithinGroupActivity(assignment) ||
        ActivityUtil.isGroupPresentationActivity(assignment)) &&
      isListByGroup(creationTableListBy) &&
      this.isGroupView();

    const rankChangeTextLabel = this.props.getRankLevelChange;

    return (
      <div className="submission-overview__header">
        <AvatarDisplay
          user={student.user}
          kritikScore={getNewKS(student)}
          group={shouldShowGroupProfile ? creation.group : null}
          hoverable={false}
        />
        <div className="info">
          <div className="submission-overview__header-title">
            <h3 className="header-3" data-testid="submission-overview-user-name">
              {creation.group && shouldShowGroupProfile ? creation.group.name : student.user.profile.name}
            </h3>
            {!shouldShowGroupProfile && (
              <Button
                type="secondary"
                data-testid="view-as-student"
                onClick={() => this.setState({ isModalOpen: true })}
              >
                <TranslatedText i18nKey="Activity.ViewAsStudent" />
              </Button>
            )}
          </div>

          <div className="pill-container">
            <div className="groupCard">
              {(creation.group || creation.userGroup) && (
                <StatusLabel
                  className="group-pill"
                  status="good"
                  label={
                    shouldShowGroupProfile
                      ? `${(creation.group || creation.userGroup).members.length} group members`
                      : (creation.group || creation.userGroup).name
                  }
                />
              )}
              <div className="cardDisplay">
                {(ActivityUtil.isGroupAssignment(assignment) ||
                  ActivityUtil.isWithinGroupActivity(assignment) ||
                  ActivityUtil.isGroupPresentationActivity(assignment)) &&
                  (creation.group || creation.userGroup) && (
                    <PanelDisplay>
                      <GroupCard group={creation.group || creation.userGroup} disabled />
                    </PanelDisplay>
                  )}
              </div>
            </div>

            {this.displayLabels(creation)}

            {shouldShowProfGradingChangeSideBox(assignment) && !shouldShowGroupProfile && (
              <div className="ranked-up">
                <StatusLabel
                  className="ranked-up-pill"
                  status="rankup"
                  label={rankChangeTextLabel}
                  icon={<i className="fa fa-star" />}
                />
                <div className="ranked-up-box">
                  <GradingChangeSideBox />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="submission-overview">
        {this.renderHeader()}
        <TabMenu
          labels={this.getOverviewTabs()}
          onSelect={(selection: any) => {
            return this.changeSelectedView(selection);
          }}
          activeLabel={this.state.selectedView}
        />
        <TabMenuItem>{this.renderViewContent()}</TabMenuItem>
        <ViewAsStudentModal
          isOpen={this.state.isModalOpen}
          handleClose={() => this.setState({ isModalOpen: false })}
          student={{
            name: (this.props.student.user as User).profile.name,
            id: this.props.student._id,
            email: (this.props.student.user as User).email,
          }}
          redirectUrl={`/course/${this.props.student.course}/assignment/${this.props.submission.assignment}`}
        />
      </div>
    );
  }
}

const CreationOverview = (props: any) => {
  const getRankLevelChange = useGetRankLevelChange(props.scoreHistories, props.assignment);

  return <CreationOverviewComponent {...props} getRankLevelChange={getRankLevelChange} />;
};

export default withRouter(connect(mapStateToProps, { getAvgGroupScoresByCreationId })(CreationOverview));
