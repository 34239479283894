import { useState } from 'react';
import { RouterProp, withRouter } from 'utils/withRouter';

import { Activity } from '@kritik/types.generated';
import * as ActivityUtils from '@kritik/utils/activity';
import * as statusUtil from '@kritik/utils/stage';
import { isFinalized } from '@kritik/utils/stage';
import { deleteAssignment, deleteFinalizedActivity } from 'actions/activity';
import { BetaBadge } from 'components/Badges';
import { TranslatedText } from 'components/TranslatedText';
import { Button, ButtonContainer, ButtonWithSelect } from 'components/buttons';
import Confirm from 'components/modals/ConfirmModal';
import { useIsFeatureFlagEnabled } from 'context/growthbookContext';
import { localize } from 'locales';
import { connect } from 'react-redux';
import GroupPresentationActivityButtons from './GroupPresentationActivityButtons';
import IndividualPresentationActivityButtons from './IndividualPresentationActivityButtons';
import { initiateAiContentCheck } from './utils';

export const REVERT_ACTIVITY_STAGE = localize({ message: 'Activity.Details.Action.Revert' });
export const ADVANCE_ACTIVITY_STAGE = localize({ message: 'Activity.AdvanceStage.Title' });
const AI_CONTENT_CHECK = localize({ message: 'Activity.Details.Action.ContentCheck' });
const DUPLICATE = localize({ message: 'Activity.Details.Action.Duplicate' });
const DELETE = localize({ message: 'Activity.Details.Action.Delete' });

function EditActivityButton({ navigateToEditActivity }: any) {
  return (
    <Button type="secondary" onClick={navigateToEditActivity} data-testid="edit-activity">
      <TranslatedText i18nKey="Activity.Details.Action.Edit" />
    </Button>
  );
}

type ConfirmDeleteProps = {
  activity: Activity;
  isDeleting: boolean;
  setIsDeleting: (value: boolean) => void;
  handleDeleteActivity: () => void;
};

const ConfirmDelete = ({ activity, isDeleting, setIsDeleting, handleDeleteActivity }: ConfirmDeleteProps) => {
  return (
    <Confirm
      isOpen={isDeleting}
      onCancel={() => {
        setIsDeleting(false);
      }}
      onConfirm={() => {
        setIsDeleting(false);
        handleDeleteActivity();
      }}
      cancelButton={localize({ message: 'Activity.Details.Delete.Cancel' })}
      confirmButton={localize({ message: 'Activity.Details.Delete.Confirm' })}
      title={localize({ message: 'Activity.Details.Delete.Title' })}
      description={`${localize({ message: 'Activity.Details.Delete.Description' })}
            ${
              isFinalized({ assignment: activity })
                ? localize({ message: 'Activity.Details.Delete.FinalizedWarning' })
                : ''
            }
          `}
    />
  );
};

type ActivityHeaderButtonsProps = {
  assignment: Activity;
  submissions: any;
  course: any;
  deleteAssignment: (params: { id: string; courseId: string }) => void;
  deleteFinalizedActivity: (params: { id: string; courseId: string }) => void;
  openRevertActivityModal: () => void;
  openAdvanceActivityStageModal: () => void;
};

export const ActivityHeaderButtons = connect(null, { deleteAssignment, deleteFinalizedActivity })(({
  assignment,
  submissions,
  course,
  deleteAssignment,
  deleteFinalizedActivity,
  openRevertActivityModal,
  openAdvanceActivityStageModal,
}: ActivityHeaderButtonsProps) => {
  const isScheduled = () => {
    if (ActivityUtils.isCalibrationActivity(assignment)) {
      return statusUtil.getEvaluationStage(assignment);
    }
    return statusUtil.getCreationStage(assignment).endDate;
  };

  if (ActivityUtils.isGroupPresentationActivity(assignment)) {
    return (
      <GroupPresentationActivityButtons
        activity={assignment}
        isScheduled={isScheduled()}
        submissions={submissions}
        openRevertActivityModal={openRevertActivityModal}
        openAdvanceActivityStageModal={openAdvanceActivityStageModal}
      />
    );
  }
  if (ActivityUtils.isIndividualPresentationActivity(assignment)) {
    return (
      <IndividualPresentationActivityButtons
        activity={assignment}
        isScheduled={isScheduled()}
        submissions={submissions}
        course={course}
        openRevertActivityModal={openRevertActivityModal}
        openAdvanceActivityStageModal={openAdvanceActivityStageModal}
      />
    );
  }
  return (
    <OtherActivitiesButtons
      assignment={assignment}
      isScheduled={isScheduled()}
      submissions={submissions}
      deleteAssignment={deleteAssignment}
      deleteFinalizedActivity={deleteFinalizedActivity}
      openRevertActivityModal={openRevertActivityModal}
      openAdvanceActivityStageModal={openAdvanceActivityStageModal}
    />
  );
});

type OtherActivitiesButtonsProps = {
  assignment: Activity;
  isScheduled: boolean;
  deleteAssignment: (params: { id: string; courseId: string }) => void;
  deleteFinalizedActivity: (params: { id: string; courseId: string }) => void;
  openRevertActivityModal: () => void;
  openAdvanceActivityStageModal: () => void;
  router: RouterProp;
};

const OtherActivitiesButtons = withRouter(
  ({
    assignment,
    isScheduled,
    router,
    deleteAssignment,
    deleteFinalizedActivity,
    openRevertActivityModal,
    openAdvanceActivityStageModal,
  }: OtherActivitiesButtonsProps) => {
    const exportAiContentCheckEnabled = useIsFeatureFlagEnabled('export_ai_content_report');
    const canAdvanceStage =
      statusUtil.isScheduled(assignment) &&
      !statusUtil.isDraft({ assignment }) &&
      !statusUtil.isGradingOrLater(assignment);
    const allButtonOptions = [
      {
        value: REVERT_ACTIVITY_STAGE,
        disabled: !statusUtil.isCreateOrLater(assignment),
        label: (
          <>
            <span>{REVERT_ACTIVITY_STAGE}</span>
            <span style={{ marginLeft: '0.5em' }}>
              <BetaBadge />
            </span>
          </>
        ),
        testid: 'revert-activity-stage',
      },
      canAdvanceStage && {
        value: ADVANCE_ACTIVITY_STAGE,
        label: <span>{ADVANCE_ACTIVITY_STAGE}</span>,
        testid: 'advance-activity-stage',
      },
      {
        value: AI_CONTENT_CHECK,
        disabled: false,
        label: AI_CONTENT_CHECK,
        testid: 'ai-content-check',
      },
      {
        value: DUPLICATE,
        disabled: false,
        label: DUPLICATE,
        testid: 'duplicate-activity',
      },
      {
        value: DELETE,
        disabled: false,
        label: DELETE,
        testid: 'delete-activity',
      },
    ];
    const buttonOptions = allButtonOptions.filter((option) => {
      if (
        option.value === AI_CONTENT_CHECK &&
        (!exportAiContentCheckEnabled || !statusUtil.isEvaluateOrLater(assignment))
      ) {
        return false;
      }
      return true;
    });

    const { courseId, assignmentId: activityId } = router.params;
    const [isDeleting, setIsDeleting] = useState(false);

    const navigateToSchedule = () => {
      router.push(`/course/${courseId}/assignment/${activityId}/schedule`);
    };

    const navigateToEditActivity = () => {
      router.push(`/course/${courseId}/assignment/${activityId}/edit-assignment`);
    };

    const handleDeleteActivity = () => {
      const deleteParams = { id: activityId, courseId: courseId };
      if (isFinalized({ assignment })) {
        deleteFinalizedActivity(deleteParams);
      } else {
        deleteAssignment(deleteParams);
      }
    };

    const handleButtonSelect = (e: any) => {
      const option = e.target.value;
      switch (option) {
        case REVERT_ACTIVITY_STAGE:
          return openRevertActivityModal();
        case ADVANCE_ACTIVITY_STAGE:
          return openAdvanceActivityStageModal();
        case AI_CONTENT_CHECK:
          return initiateAiContentCheck(activityId);
        case DUPLICATE:
          return router.push(`/course/${courseId}/assignment/${activityId}/duplicate-assignment`);
        case DELETE:
          return setIsDeleting(true);
        default:
          return null;
      }
    };

    if (statusUtil.isFinalized({ assignment })) {
      return (
        <>
          <ConfirmDelete
            activity={assignment}
            isDeleting={isDeleting}
            setIsDeleting={setIsDeleting}
            handleDeleteActivity={handleDeleteActivity}
          />
          <ButtonContainer align="right">
            <ButtonWithSelect
              type="secondary"
              title={localize({ message: 'Activity.Details.Action.Title' })}
              options={buttonOptions}
              onChange={handleButtonSelect}
              testid="activity-details-page_actions"
              position="right"
              className="action_container-activity-details"
            />
            <EditActivityButton navigateToEditActivity={navigateToEditActivity} />
          </ButtonContainer>
        </>
      );
    }

    if (statusUtil.isGrading({ assignment })) {
      return (
        <>
          <ConfirmDelete
            activity={assignment}
            isDeleting={isDeleting}
            setIsDeleting={setIsDeleting}
            handleDeleteActivity={handleDeleteActivity}
          />
          <ButtonContainer align="right">
            <ButtonWithSelect
              type="secondary"
              title={localize({ message: 'Activity.Details.Action.Title' })}
              options={buttonOptions}
              onChange={handleButtonSelect}
              testid="activity-details-page_actions"
              position="right"
              className="action_container-activity-details"
            />
            <EditActivityButton navigateToEditActivity={navigateToEditActivity} />
          </ButtonContainer>
        </>
      );
    }

    return (
      <>
        <ConfirmDelete
          activity={assignment}
          isDeleting={isDeleting}
          setIsDeleting={setIsDeleting}
          handleDeleteActivity={handleDeleteActivity}
        />
        <ButtonContainer align="right">
          <ButtonWithSelect
            type="secondary"
            title={localize({ message: 'Activity.Details.Action.Title' })}
            options={buttonOptions}
            onChange={handleButtonSelect}
            testid="activity-details-page_actions"
            position="right"
            className="action_container-activity-details"
          />
          <EditActivityButton navigateToEditActivity={navigateToEditActivity} />
          <Button
            data-testid="edit-or-schedule-activity"
            type="primary"
            onClick={navigateToSchedule}
            inputType="button"
          >
            {isScheduled ? (
              <TranslatedText i18nKey="Activity.Details.Action.EditSchedule" />
            ) : (
              <TranslatedText i18nKey="Activity.Details.Action.ScheduleActivity" />
            )}
          </Button>
        </ButtonContainer>
      </>
    );
  }
);
