const MIN_PASSWORD_LENGTH = 12;
const MAX_PASSWORD_LENGTH = 128;

export function validateNewPassword(password: string): { error: string } {
  let error: string | null = null;

  if (password.length < MIN_PASSWORD_LENGTH) {
    error = `Your password must be at least ${MIN_PASSWORD_LENGTH} characters long.`;
  } else if (password.length > MAX_PASSWORD_LENGTH) {
    error = `Your password must be no more than ${MAX_PASSWORD_LENGTH} characters long.`;
  } else if (!/[a-zA-Z]/.test(password)) {
    error = 'Your password must contain at least one alphabetic character (A-Z or a-z).';
  } else if (!/[0-9]/.test(password)) {
    error = 'Your password must contain at least one numeric character (0-9).';
  }

  return { error };
}
