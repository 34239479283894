import { connect } from 'react-redux';

import { navigateToActivityPage, navigateToActivityPath } from 'actions/activity';
import getPaginatedResults from 'actions/pagination/actions';
import { SORT_TYPE } from 'actions/pagination/constants';
import { Button } from 'components/buttons';
import SORT_ARROWS from 'images/sort-arrows';
import { setCurrentPage, setSortedColumn, setTableType } from 'redux/creationTable/actions';
import { selectCreationTableColumns, selectCreationTableViewType } from 'redux/creationTable/select';
import { selectCurrentActivity } from 'selectors/activity';
import { getUpdatedPath } from '../PaginationMenu/utils';

function SortByGroup(props: any) {
  const searchParams = new URLSearchParams(document.location.search);
  const sortOrder = searchParams?.get('sortOrder');
  const sortBy = searchParams?.get('sortBy');

  return (
    <Button
      id="sort-by-group"
      type="secondary"
      onClick={(e: any) => {
        const newParams = {
          sortOrder: 1,
        };
        if (sortBy === SORT_TYPE.GROUP) {
          newParams.sortOrder = sortOrder === '-1' ? 1 : -1;
        }
        const newUrl = getUpdatedPath(
          window.location,
          { param: 'sortBy', value: SORT_TYPE.GROUP },
          { param: 'sortOrder', value: newParams.sortOrder }
        );
        // Reset all columns that are sorted
        props.setSortedColumn(props.columns, null);
        props.setCurrentPage(1);
        props.navigateToActivityPath(newUrl);
        props.getPaginatedResults();
      }}
      testid="creation-list-sort-by-group"
    >
      Sort By Group{' '}
      {sortBy === SORT_TYPE.GROUP ? (
        sortOrder === '1' ? (
          <img alt="sort ascending" src={SORT_ARROWS.ASCENDING} />
        ) : (
          <img alt="sort descending" src={SORT_ARROWS.DESCENDING} />
        )
      ) : null}
    </Button>
  );
}

const mapStateToProps = (state: any) => {
  return {
    tableType: selectCreationTableViewType(state),
    courseId: state.selected.courseId,
    activityId: state.selected.assignmentId,
    activity: selectCurrentActivity(state),
    columns: selectCreationTableColumns(state),
  };
};

export default connect(mapStateToProps, {
  setTableType,
  navigateToActivityPage,
  getPaginatedResults,
  setCurrentPage,
  navigateToActivityPath,
  setSortedColumn,
})(SortByGroup);
