import { resetAsync } from 'actions/async';
import { TranslatedText } from 'components/TranslatedText';
import { Button } from 'components/buttons';
import FormContainer from 'components/core/form/Container';
import FormField from 'components/core/form/Field';
import FormRichTextEditor from 'components/core/form/RichTextEditor';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import FormTitle from 'components/core/form/Title';
import InlineInformation from 'components/layout/InlineInformation';
import { localize } from 'locales';
import { Creation } from 'old-common/types.generated';
import { useState } from 'react';
import { connect } from 'react-redux';
import {
  createCalibrationCreation,
  selectActivityEditAsyncState,
  selectCalibrationCreationEditorVisibility,
  setCalibrationCreationEditorVisibility,
  showRubricView,
  updateCalibrationCreation,
} from 'redux/activityEdit';
import { CREATE_CALIBRATION_CREATION } from 'redux/activityEdit/types';
import { selectCurrentCourse } from 'selectors/course';
import CreationEditor from './CreationEditor';
import { StarRating } from './StarRating';

const useEvalRating = (creation: any, criteria: any) => {
  const _evalRating = creation && creation.marks.length ? creation.marks : new Array(criteria.length).fill(null);
  return useState(_evalRating);
};

function CalibrationCreationEditor({
  rubric,
  activity,
  course,
  user,
  creationIndex,
  asyncState,
  calibrationCreationEditorVisibility,
  ...props
}: any) {
  if (!calibrationCreationEditorVisibility) {
    return null;
  }

  const { criteria } = rubric;
  const { calibrationCreations } = props.form.getState().values;
  const creation = calibrationCreations[creationIndex];
  const [content, setContent] = useState(creation?.content || '');
  const [instructorEvaluationNotes, setInstructorEvaluationNotes] = useState(creation?.instructorEvaluationNotes || '');
  const [files, setFileList] = useState(creation?.files || []);
  const [evalRating, setEvalRating] = useEvalRating(creation, criteria);
  const [errors, setErrors] = useState([]);

  const updateCalibrationCreations = (error: any, newCreation: Creation) => {
    if (error) {
      return setErrors(Object.values(error.errors));
    }
    props.form.mutators.setCalibrationCreation({
      marks: newCreation.teacherScore,
      content: newCreation.content,
      instructorEvaluationNotes: newCreation.instructorCreationScoreExplanation,
      files: newCreation.files,
      creationId: newCreation._id,
      index: creationIndex,
    });
  };

  const handleSubmit = () => {
    const data = {
      course: course._id,
      files,
      content,
      instructorCreationScoreExplanation: instructorEvaluationNotes,
      user,
      teacherScore: evalRating,
      calibrationCreation: true,
      rubric,
    };

    if (!data.files.length && !content.length) {
      return setErrors(['Please add content to your creation.']);
    }

    if (evalRating.includes(null)) {
      return setErrors([localize({ message: 'Rubric.Edit.Save.MissCriteria' })]);
    }

    if (creation) {
      (data as any)._id = creation._id;
      props.updateCalibrationCreation(data, updateCalibrationCreations);
    } else {
      props.createCalibrationCreation(data, updateCalibrationCreations);
    }
  };

  const text = {
    btn_view_rubric: 'View Rubric',
    btn_done: localize({ message: 'Activity.CalibrationActivity.Edit.Create' }),
    btn_cancel: 'Cancel',
    notice: 'Your creation will appear as an anonymous students’ creation to your students',
    label: localize({ message: 'Activity.CalibrationActivity.Edit.RatingLabel' }),
    editor_label: localize({ message: 'Activity.CalibrationActivity.Edit.EditorLabel' }),
  };

  const onClickFullRubric = () => {
    props.showRubricView();
  };

  return (
    <div className="calibration-creation-editor">
      <FormField>
        <InlineInformation title={text.notice} type="information" />
      </FormField>
      <FormField>
        <Button type="secondary" onClick={onClickFullRubric} data-testid="calibration_view-rubric">
          {text.btn_view_rubric}
        </Button>
      </FormField>
      <FormContainer>
        <FormTitle label={text.editor_label} />
        <FormField>
          <CreationEditor
            content={content}
            onContentChange={setContent}
            onFileChange={setFileList}
            files={files}
            testid="calibration-creation"
          />
        </FormField>
      </FormContainer>
      <FormContainer className="calibration-creation-rating-section">
        <StarRating
          rubric={rubric}
          label={text.label}
          evalRating={evalRating}
          onChange={setEvalRating}
          startingScore={props.form?.getState()?.values?.startingScore || 0}
        />
        <FormRichTextEditor
          label={<TranslatedText i18nKey="Activity.CalibrationActivity.Edit.EvaluationNotes" />}
          value={instructorEvaluationNotes}
          onChange={setInstructorEvaluationNotes}
          testid="editor-instructor-creation-score-explanation"
        />
      </FormContainer>
      <FormField>
        {errors.map((error) => {
          return <InlineInformation type="danger">{error}</InlineInformation>;
        })}
      </FormField>

      <FormSubmitButtons>
        <Button
          disabled={asyncState[CREATE_CALIBRATION_CREATION].loading}
          loading={asyncState[CREATE_CALIBRATION_CREATION].loading}
          type="primary"
          onClick={handleSubmit}
          data-testid="submit-create-calibration-creation"
          label={localize({
            message: 'Button.Label.CreateCreation',
            options: { creationType: 'calibration' },
          })}
        >
          {text.btn_done}
        </Button>
        <Button
          type="secondary"
          onClick={() => {
            props.setCalibrationCreationEditorVisibility(false);
          }}
        >
          {text.btn_cancel}
        </Button>
      </FormSubmitButtons>
    </div>
  );
}

CalibrationCreationEditor.defaultProps = {
  creation: null,
};
const mapStateToProps = (state: any) => {
  return {
    calibrationCreationEditorVisibility: selectCalibrationCreationEditorVisibility(state),
    course: selectCurrentCourse(state),
    user: state.user.authUser,
    asyncState: selectActivityEditAsyncState(state),
  };
};
export default connect(mapStateToProps, {
  setCalibrationCreationEditorVisibility,
  createCalibrationCreation,
  updateCalibrationCreation,
  showRubricView,
  resetAsync,
})(CalibrationCreationEditor);
