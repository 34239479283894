import * as activityUtils from '@kritik/utils/activity';
import { navigateToInstructorCreationView } from 'actions/activity';
import { selectSubmission } from 'actions/select';
import Cell from 'components/CreationList/Body/Cell';
import TableRow from 'components/CreationList/Body/Row';
import ToggleCell from 'components/CreationList/Body/ToggleCell';
import CreationScoreCell from 'components/CreationList/StatusCells/CreationScore';
import EvaluationScoreCell from 'components/CreationList/StatusCells/EvaluationScore';
import FeedbackScoreCell from 'components/CreationList/StatusCells/FeedbackScore';
import IssuesCell from 'components/CreationList/StatusCells/Issues';
import OverallScoreCell from 'components/CreationList/StatusCells/OverallScore';
import StudentInfoCell from 'components/CreationList/StatusCells/StudentInfo';
import localUtil from 'components/CreationList/util';
import React from 'react';
import { connect } from 'react-redux';
import { selectStudent } from 'reducers/selected';
import ActivityScoresSelector from 'redux/activityScores/select';
import { selectCreationTableListBy } from 'redux/creationTable/select';
import { getAssignment } from 'selectors/activity';
import { getCourse } from 'selectors/course';

const CalibrationRow = ({ columns, activity, creation }: any) => {
  return (
    <React.Fragment>
      <Cell column={columns[1]}>-</Cell>
      <Cell column={columns[2]} />
      <Cell column={columns[3]} />
      <Cell column={columns[4]} />
      <Cell column={columns[5]}>
        <IssuesCell creation={creation} />
      </Cell>
    </React.Fragment>
  );
};

const GroupRowByStudent = ({ columns, activity, creation, student, course }: any) => {
  return (
    <React.Fragment>
      <Cell column={columns[1]}>
        {/* @ts-expect-error TS(2786) FIXME: 'CreationScoreCell' cannot be used as a JSX compon... Remove this comment to see the full error message */}
        <CreationScoreCell creation={creation} activity={activity} />
      </Cell>
      <Cell column={columns[2]}>
        {/* @ts-expect-error TS(2786) FIXME: 'EvaluationScoreCell' cannot be used as a JSX comp... Remove this comment to see the full error message */}
        <EvaluationScoreCell student={student} creation={creation} activity={activity} course={course} />
      </Cell>
      <Cell column={columns[3]}>
        {/* @ts-expect-error TS(2786) FIXME: 'FeedbackScoreCell' cannot be used as a JSX compon... Remove this comment to see the full error message */}
        <FeedbackScoreCell student={student} course={course} creation={creation} activity={activity} />
      </Cell>
      <Cell column={columns[4]} />
      <Cell column={columns[5]}>
        <IssuesCell creation={creation} />
      </Cell>
    </React.Fragment>
  );
};
const IndividualRow = ({ columns, activity, creation, student, course }: any) => {
  return (
    <React.Fragment>
      <Cell column={columns[1]}>
        {/* @ts-expect-error TS(2786) FIXME: 'CreationScoreCell' cannot be used as a JSX compon... Remove this comment to see the full error message */}
        <CreationScoreCell creation={creation} activity={activity} />
      </Cell>
      <Cell column={columns[2]}>
        {/* @ts-expect-error TS(2786) FIXME: 'EvaluationScoreCell' cannot be used as a JSX comp... Remove this comment to see the full error message */}
        <EvaluationScoreCell student={student} creation={creation} activity={activity} course={course} />
      </Cell>
      <Cell column={columns[3]}>
        {/* @ts-expect-error TS(2786) FIXME: 'FeedbackScoreCell' cannot be used as a JSX compon... Remove this comment to see the full error message */}
        <FeedbackScoreCell student={student} activity={activity} />
      </Cell>
      <Cell column={columns[4]}>
        {/* @ts-expect-error TS(2786) FIXME: 'OverallScoreCell' cannot be used as a JSX compone... Remove this comment to see the full error message */}
        <OverallScoreCell student={student} course={course} creation={creation} activity={activity} />
      </Cell>
      <Cell column={columns[5]}>
        <IssuesCell creation={creation} />
      </Cell>
    </React.Fragment>
  );
};

const GroupRow = (props: any) => {
  const {
    columns,
    activity,
    creation,
    listBy,
    parentId,
    handleClickToggle,
    isExpand,
    evaluationScore,
    feedbackScore,
    student,
    course,
  } = props;
  return (
    <React.Fragment>
      <Cell column={columns[1]}>
        {/* @ts-expect-error TS(2786) FIXME: 'CreationScoreCell' cannot be used as a JSX compon... Remove this comment to see the full error message */}
        <CreationScoreCell creation={creation} activity={activity} />
      </Cell>
      <Cell column={columns[2]}>
        {/* @ts-expect-error TS(2786) FIXME: 'EvaluationScoreCell' cannot be used as a JSX comp... Remove this comment to see the full error message */}
        <EvaluationScoreCell
          evaluationScore={evaluationScore}
          activity={activity}
          student={student}
          course={course}
          isGroupItem={localUtil.isGroupItem({ parentId }, listBy)}
        />
      </Cell>
      <Cell column={columns[3]}>
        {/* @ts-expect-error TS(2786) FIXME: 'FeedbackScoreCell' cannot be used as a JSX compon... Remove this comment to see the full error message */}
        <FeedbackScoreCell
          feedbackScore={feedbackScore}
          activity={activity}
          student={student}
          isGroupItem={localUtil.isGroupItem({ parentId }, listBy)}
        />
      </Cell>
      <Cell column={columns[4]} />
      <Cell column={columns[5]}>
        <IssuesCell creation={creation} />
      </Cell>
      {localUtil.isGroupItem({ parentId }, listBy) && (
        <ToggleCell column={columns[6]} onClick={handleClickToggle} isExpand={isExpand} />
      )}
    </React.Fragment>
  );
};

const Row = (props: any) => {
  const {
    data,
    index,
    style,
    activity,
    course,
    listBy,
    navigateToInstructorCreationView,
    selectSubmission,
    selectStudent,
    activityScoresMap,
  } = props;
  const { stickyIndices, columns, toggleGroupMembers } = data;
  if (stickyIndices && stickyIndices.includes(index)) {
    return null;
  }
  if (localUtil.isListByGroup(listBy)) {
    if (columns.length !== 7) {
      return null;
    }
  } else if (columns.length !== 6) {
    return null;
  }

  const item = data.rowList[index];
  if (!item) {
    return <TableRow />;
  }
  const { student, creation, group } = item;

  const renderCols = () => {
    if (activityUtils.isCalibrationActivity(activity)) {
      return <CalibrationRow columns={columns} activity={activity} creation={creation} />;
    }
    if (activityUtils.isGroupAssignment(activity) || activityUtils.isGroupPresentationActivity(activity)) {
      if (localUtil.isListByGroup(listBy)) {
        const { parentId, isVisible, isExpand } = item;
        const handleClickToggle = () => {
          toggleGroupMembers(index);
        };
        if (!parentId && props.isActivityScoresLoaded) {
          const { avgGroupFeedbackScore, avgEvalScore } = activityScoresMap[creation._id];
          return (
            <GroupRow
              columns={columns}
              activity={activity}
              creation={creation}
              listBy={listBy}
              evaluationScore={avgEvalScore}
              feedbackScore={avgGroupFeedbackScore}
              parentId={parentId}
              isExpand={isExpand}
              handleClickToggle={handleClickToggle}
              student={student}
              course={course}
            />
          );
        }
        if (isVisible) {
          return (
            <GroupRowByStudent
              columns={columns}
              activity={activity}
              creation={creation}
              student={student}
              course={course}
            />
          );
        }
        return null;
      }
      return (
        <GroupRowByStudent
          columns={columns}
          activity={activity}
          creation={creation}
          student={student}
          course={course}
        />
      );
    }
    return (
      <IndividualRow columns={columns} activity={activity} creation={creation} student={student} course={course} />
    );
  };
  const renderStudentInfo = () => {
    if (localUtil.isListByGroup(listBy)) {
      const infoCellColumn = { ...columns[0] };
      if (!localUtil.isGroupItem({ parentId: item.parentId }, listBy)) {
        infoCellColumn.align = 'left';
        infoCellColumn.isGroupMember = true;
      }
      return (
        <Cell column={infoCellColumn}>
          <StudentInfoCell
            student={student}
            group={group}
            isListByGroup={localUtil.isListByGroup(listBy)}
            creation={creation}
          />
        </Cell>
      );
    }
    return (
      <Cell column={columns[0]}>
        <StudentInfoCell
          student={student}
          group={group}
          isListByGroup={localUtil.isListByGroup(listBy)}
          creation={creation}
        />
      </Cell>
    );
  };
  const handleClickRow = () => {
    if (creation) {
      selectSubmission(creation._id);
      selectStudent(student._id);
      navigateToInstructorCreationView({
        assignmentId: activity._id,
        courseId: activity.course,
        creationId: creation._id,
        groupView: !!localUtil.isGroupItem({ parentId: item.parentId }, listBy),
      });
    }
  };

  return (
    <TableRow
      isWarning={localUtil.hasCreationWarning(creation, activity)}
      disableHover={!creation}
      style={style}
      onClick={() => {
        return handleClickRow();
      }}
      testid={`scoring_creation-row-${student._id}`}
    >
      {renderStudentInfo()}
      {renderCols()}
    </TableRow>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
    course: getCourse(state, state.selected.courseId),
    listBy: selectCreationTableListBy(state),
    activityScoresMap: ActivityScoresSelector.selectActivityScoresMap(state),
    isActivityScoresLoaded: ActivityScoresSelector.isActivityScoresLoaded(state),
  };
};

export default connect(mapStateToProps, {
  navigateToInstructorCreationView,
  selectSubmission,
  selectStudent,
})(Row);
