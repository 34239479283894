import { CreationScoreUtil } from '@kritik/utils/grade';
import InstructorCreationComment from 'components/CommentOnContent/CreationComment';
import InstructorCreationScoreEdit from 'components/Creation/Evaluate/InstructorCreationScoreEdit';
import StarRubricMarks from 'components/General/StarRubricMarks';
import { STAR_RUBRIC_MARKS } from 'components/General/constant';

const CreationScore = ({
  creation,
  activity,
  isCommentingOnCreation,
  resetEditor,
  isEditingScore,
  course,
  viewClassAverage,
  viewAIScore,
}: any) => {
  const getComparisonMarks = () => {
    let comparisonMarks = [];
    if (viewClassAverage) {
      comparisonMarks.push({ type: 'class-average', marks: activity.averageGrade });
    }
    if (viewAIScore && creation.aiScores?.length > 0) {
      comparisonMarks.push({
        type: 'ai-score',
        marks: creation.aiScores
          .sort((a, b) => a.rubricCriterionIndex - b.rubricCriterionIndex)
          .map(({ score }) => score),
      });
    }
    return comparisonMarks;
  };
  if (isCommentingOnCreation) {
    return (
      <InstructorCreationComment
        creation={creation}
        activityId={activity._id}
        creationUserId={creation.user._id}
        isInstructor
        isEdit
        onClose={resetEditor}
      />
    );
  }
  if (isEditingScore) {
    return (
      <InstructorCreationScoreEdit
        activity={activity}
        course={course}
        creation={creation}
        rubric={activity.rubric}
        onSubmit={resetEditor}
        onCancel={resetEditor}
      />
    );
  }
  return (
    <StarRubricMarks
      tableType={STAR_RUBRIC_MARKS.OVERALL_CREATION_SCORE}
      rubric={activity.rubric}
      marks={CreationScoreUtil.getCreationMarks({ creation, activity })}
      criteriaModeScoringOverruled={CreationScoreUtil.getModeScoringValidityForAllCriteria(creation, activity.rubric)}
      comparisonMarks={getComparisonMarks()}
      type="primary"
      showExactScore
    />
  );
};

export default CreationScore;
