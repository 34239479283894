import { isGroupAssignment, isGroupPresentationActivity, isWithinGroupActivity } from '@kritik/utils/activity';
import { displayPercent } from '@kritik/utils/format';
import { CreationScoreUtil } from '@kritik/utils/grade';
import { navigateToInstructorCreationView } from 'actions/activity';
import { selectSubmission } from 'actions/select';
import SortByGroup from 'components/CreationList/Menu/SortByGroup';
import ViewBy from 'components/CreationList/Menu/ViewBy';
import StudentInfoCell from 'components/CreationList/StatusCells/StudentInfo';
import { TABLE_TYPE_OPTIONS } from 'components/CreationList/constant';
import PaginationControls from 'components/PaginationControls';
import { TranslatedText } from 'components/TranslatedText';
import Search from 'components/core/input/Search';
import SORT_ARROWS from 'images/sort-arrows';
import { localize } from 'locales';
import * as _ from 'lodash-es';
import { useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { LoaderFunctionArgs, useLoaderData, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { selectStudent } from 'reducers/selected';
import { setTableType } from 'redux/creationTable/actions';
import { assignmentService } from 'services/index';

const COLUMNS = {
  DIFF: 'DIFF',
  NAME: 'NAME',
};

async function loader(args: LoaderFunctionArgs) {
  const url = new URL(args.request.url);
  const defaultOptions = {
    page: '1',
    searchString: '',
    resultsPerPage: '25',
    sortBy: COLUMNS.DIFF,
    sortOrder: '-1',
  };
  const searchParams = Object.fromEntries(url.searchParams.entries());
  const { page, searchString, resultsPerPage, sortBy, sortOrder } = {
    ...defaultOptions,
    ...searchParams,
  };

  const [creationsResult, activityResult] = await Promise.all([
    assignmentService().listCreationsByActivity({
      ...defaultOptions,
      ...searchParams,
      activityId: args.params.assignmentId,
    }),
    assignmentService().get({
      id: args.params.assignmentId,
    }),
  ]);

  return {
    activity: activityResult.data,
    items: creationsResult.data.creations,
    count: creationsResult.data.numResults,
    currentPage: parseInt(page, 10),
    searchString,
    resultsPerPage: parseInt(resultsPerPage, 10),
    sortBy,
    sortOrder,
  };
}

function ActivityDetailsCreationScoreComparisonTable() {
  const params = useParams();
  const data = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  const setSearchParams = useSearchParams()[1];

  const dispatch = useDispatch();
  const store = useStore();

  useEffect(() => {
    //needed while other tables are still using redux to handle filters
    setTableType(TABLE_TYPE_OPTIONS.SCORE_COMPARISON)(dispatch);
    if (data.sortBy !== COLUMNS.DIFF && data.sortBy !== COLUMNS.NAME) {
      setActiveColumn({ activeColumn: COLUMNS.DIFF });
    }
  }, []);

  const listByGroup = isGroupPresentationActivity(data.activity) || isGroupAssignment(data.activity);

  const sortOrder = data.sortOrder === '1' ? 'asc' : 'desc';
  const activeColumn = data.sortBy;

  const setActiveColumn = ({ activeColumn }) => {
    setSearchParams((prevParams) => {
      const prevSearchParams = Object.fromEntries(prevParams);
      return {
        ...prevSearchParams,
        sortBy: activeColumn,
        sortOrder:
          prevSearchParams.sortBy !== activeColumn
            ? activeColumn === COLUMNS.NAME
              ? '1'
              : '-1'
            : prevSearchParams.sortOrder === '1'
              ? '-1'
              : '1',
      };
    });
  };

  return (
    <div key={`score-comparison-table-${params.assignmentId}`}>
      <div className="score-comparison-table-wrapper__menu">
        <div className="score-comparison-table-menu">
          <div className="score-comparison-table-menu__section">
            <div className="score-comparison-table-menu__item">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const formData = new FormData(e.currentTarget);
                  setSearchParams((prevParams) => ({
                    ...Object.fromEntries(prevParams),
                    searchString: formData.get('searchString') as string,
                    page: '1', // Reset to first page when changing search string
                  }));
                }}
              >
                <Search initialValue={data.searchString} name="searchString" label={localize({ message: 'Search' })} />
              </form>
            </div>
            <div className="score-comparison-table-menu__item">
              {isWithinGroupActivity(data.activity) && <SortByGroup />}
            </div>
          </div>
          <div className="score-comparison-table-menu__item">
            <ViewBy />
          </div>
        </div>
        <PaginationControls
          currentPage={data.currentPage}
          resultsPerPage={data.resultsPerPage}
          totalItems={data.count}
        />
      </div>
      <div className="score-comparison-table-wrapper__table">
        <table className="score-comparison-table">
          <thead>
            <tr className="score-comparison-table-header">
              <th
                scope="col"
                className="score-comparison-table-header-cell score-comparison-table-header-cell__pointer"
                id="name"
                onClick={() => setActiveColumn({ activeColumn: COLUMNS.NAME })}
              >
                <TranslatedText i18nKey="Activity.Details.ScoreComparisonTable.Name" />
                {activeColumn === COLUMNS.NAME && (
                  <div className="score-comparison-table-header-cell__sorting">
                    <img
                      alt="sort ascending"
                      src={sortOrder === 'asc' ? SORT_ARROWS.ASCENDING : SORT_ARROWS.DESCENDING}
                    />
                  </div>
                )}
              </th>
              <th
                scope="col"
                className="score-comparison-table-header-cell score-comparison-table-header-cell__centered"
                id="AI Score"
              >
                <TranslatedText i18nKey="Activity.Details.ScoreComparisonTable.AiScore" />
              </th>
              <th
                scope="col"
                className="score-comparison-table-header-cell score-comparison-table-header-cell__centered"
                id="Creation Score"
              >
                <TranslatedText i18nKey="Activity.Details.ScoreComparisonTable.CreationScore" />
              </th>
              <th
                scope="col"
                className={
                  'score-comparison-table-header-cell score-comparison-table-header-cell__centered score-comparison-table-header-cell__pointer'
                }
                id="Diff"
                onClick={() => setActiveColumn({ activeColumn: COLUMNS.DIFF })}
              >
                <TranslatedText i18nKey="Activity.Details.ScoreComparisonTable.Diff" />
                {activeColumn === COLUMNS.DIFF && (
                  <div className="score-comparison-table-header-cell__sorting">
                    <img
                      alt="sort ascending"
                      src={sortOrder === 'asc' ? SORT_ARROWS.ASCENDING : SORT_ARROWS.DESCENDING}
                    />
                  </div>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.items.map((creation) => {
              const creationScore = CreationScoreUtil.calcAvgCreationScore({
                creation,
                rubric: data.activity.rubric,
                startingScore: data.activity.startingScore,
                lateCreationPenaltyPercentage: data.activity.lateCreationPenaltyPercentage,
              });

              const diff =
                !creation.aiScore && creation.aiScore !== 0
                  ? '-'
                  : displayPercent(_.round(creation.aiScore - creationScore));

              return (
                <tr
                  className="score-comparison-table__row"
                  onClick={() => {
                    dispatch(selectStudent(creation.student._id));
                    dispatch(selectSubmission(creation._id));
                    navigateToInstructorCreationView({
                      assignmentId: data.activity._id,
                      courseId: data.activity.course._id || data.activity.course,
                      creationId: creation._id,
                      groupView: listByGroup,
                    })(dispatch, store.getState);
                  }}
                >
                  <td className="score-comparison-table__cell" style={{ color: '#4f4f4f' }}>
                    <StudentInfoCell
                      student={creation.student}
                      group={creation.group}
                      isListByGroup={listByGroup}
                      creation={creation}
                    />
                  </td>
                  <td className="score-comparison-table__cell score-comparison-table__cell-centered">
                    {!creation.aiScore && creation.aiScore !== 0 ? '-' : displayPercent(_.round(creation.aiScore))}
                  </td>
                  <td className="score-comparison-table__cell score-comparison-table__cell-centered">
                    {displayPercent(_.round(creationScore))}
                  </td>
                  <td
                    className={'score-comparison-table__cell score-comparison-table__cell-centered'}
                    style={{
                      color: Math.abs(parseInt(diff)) > 20 ? 'red' : 'inherit',
                    }}
                  >
                    {diff}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <PaginationControls currentPage={data.currentPage} resultsPerPage={data.resultsPerPage} totalItems={data.count} />
    </div>
  );
}

ActivityDetailsCreationScoreComparisonTable.loader = loader;

export { ActivityDetailsCreationScoreComparisonTable };
