import { simplePercent } from '@kritik/utils/format';
import { CreationScoreUtil, GradingScoreUtil } from '@kritik/utils/grade';
import { calcWeightedScoreFromMark, getLevelsInCriteria, isRowBinary } from '@kritik/utils/rubric';
import Tooltip from '@material-ui/core/Tooltip';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import { Rating } from '@material-ui/lab';
import classNames from 'classnames';
import { ScoreChangePerCriteria } from 'components/Assignment/Scores';
import ShortenText from 'components/General/ShortenText';
import { TranslatedText } from 'components/TranslatedText';
import InfoPopup from 'components/core/InfoPopup';
import ModeLabel from 'components/layout/ModeLabel';
import { localize } from 'locales';
import { round } from 'lodash-es';
import { Activity, Course, Rubric } from 'old-common/types.generated';
import React from 'react';
import { connect } from 'react-redux';
import { getAssignment } from 'selectors/activity';
import { getCourse } from 'selectors/course';
import { STAR_RUBRIC_MARKS } from './constant';

type StarRubricMarksProps = {
  rubric: Rubric;
  marks?: any[];
  tableType: string;
  course: Course;
  comparisonMarks?: ComparisonMarks[];
  scoreDiffs?: any[];
  type: 'primary' | 'secondary';
  showExactScore?: boolean;
  isInstructor?: boolean;
  maxGradingScorePerEval?: any;
  precision?: any;
  isCalibrationActivity?: boolean;
  criteriaModeScoringOverruled?: any[];
  activity: Activity;
  startingScore?: number;
};

type ComparisonMarks = {
  marks: any[];
  type: string;
};

const useColorClass = (type) => {
  const colorClass = classNames('score-display__star', {
    'score-display__star--pink': type === 'primary',
    'score-display__star--purple': type === 'secondary',
  });

  const inverseColorClass = classNames('score-display__star', {
    'score-display__star--pink': type === 'secondary',
    'score-display__star--purple': type === 'primary',
  });
  return { colorClass, inverseColorClass };
};

function StarRubricMarks({
  rubric,
  marks,
  comparisonMarks,
  scoreDiffs,
  type,
  showExactScore = true,
  isInstructor,
  maxGradingScorePerEval,
  precision,
  isCalibrationActivity,
  tableType,
  criteriaModeScoringOverruled,
  course,
  activity,
  startingScore,
}: StarRubricMarksProps) {
  const isGradingScoreTableOrDefault =
    tableType !== STAR_RUBRIC_MARKS.OVERALL_CREATION_SCORE && tableType !== STAR_RUBRIC_MARKS.STUDENT_CREATION_SCORE;

  const isOverallCreationScoreTable = tableType === STAR_RUBRIC_MARKS.OVERALL_CREATION_SCORE;

  const { isPercentage } = course.markingScheme;

  const showGradingScore =
    (isGradingScoreTableOrDefault || isOverallCreationScoreTable) && scoreDiffs && Boolean(scoreDiffs.length);

  const scoreColumnLabel = showGradingScore ? (
    <TranslatedText i18nKey="StarRubricMarks.ScoreColumnLabel.GradingScore" />
  ) : isPercentage && (isInstructor || activity?.startingScore > 0) ? (
    '%'
  ) : (
    localize({ message: 'Activity.OverallCreationScore.Header.Points' })
  );

  const showStartingScoreInfo = (!showGradingScore && isInstructor && activity?.startingScore > 0) || startingScore > 0;

  return (
    <div className="grade-display" data-testid="creation-table-view-rubric-grade">
      <table className="score-display">
        <caption className="visually-hidden">
          <TranslatedText i18nKey="Activity.OverallCreationScore.Table.Caption" />
        </caption>
        <thead>
          <tr>
            <th scope="col" className="header_criterion">
              <TranslatedText i18nKey="Activity.OverallCreationScore.Header.Criterion" />
            </th>
            <th scope="col" className="header_weight">
              <TranslatedText i18nKey="Activity.OverallCreationScore.Header.Weight" />
            </th>
            <th scope="col" className="header_stars">
              <TranslatedText i18nKey="Activity.OverallCreationScore.Header.Score" />
            </th>
            <th scope="col" className="header_score">
              {scoreColumnLabel}{' '}
              {showStartingScoreInfo && (
                <InfoPopup
                  btnClassName="score-display_info-popup"
                  title={localize({
                    message: 'Activity.OverallCreationScore.Header.StartingScoreInfo.Title',
                  })}
                  description={
                    <TranslatedText
                      i18nKey="Activity.OverallCreationScore.Header.StartingScoreInfo.Description"
                      values={{
                        startingScore: activity?.startingScore?.toString() || startingScore?.toString(),
                      }}
                    />
                  }
                />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {rubric.criteria.map((criterion: any, criterionIndex: any) => {
            return (
              <React.Fragment key={criterionIndex}>
                <ScoreRow
                  isCalibrationActivity={isCalibrationActivity}
                  rubric={rubric}
                  criterionIndex={criterionIndex}
                  tableType={tableType}
                  marks={marks}
                  scoreDiffs={scoreDiffs}
                  comparisonMarks={comparisonMarks}
                  isInstructor={isInstructor}
                  precision={precision}
                  maxGradingScorePerEval={maxGradingScorePerEval}
                  criteriaModeScoringOverruled={criteriaModeScoringOverruled}
                  showExactScore={showExactScore}
                  showGradingScore={showGradingScore}
                  isPercentage={isPercentage}
                  activity={activity}
                  type={type}
                  startingScore={startingScore}
                />
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

type ScoreRowProps = {
  rubric: Rubric;
  comparisonMarks: any[];
  scoreDiffs: any[];
  showExactScore: boolean;
  isInstructor: boolean;
  precision: any;
  isCalibrationActivity: boolean;
  tableType: string;
  criteriaModeScoringOverruled: any[];
  criterionIndex: number;
  type: string;
  marks: any[];
  maxGradingScorePerEval: number;
  showGradingScore: boolean;
  isPercentage: boolean;
  activity: Activity;
  startingScore?: number;
};

const ScoreRow = ({
  isCalibrationActivity,
  criterionIndex,
  tableType,
  marks,
  rubric,
  scoreDiffs,
  comparisonMarks,
  isInstructor,
  precision,
  maxGradingScorePerEval,
  criteriaModeScoringOverruled,
  showExactScore,
  showGradingScore,
  isPercentage,
  activity,
  type,
  startingScore,
}: ScoreRowProps) => {
  const { criteria, grid } = rubric;

  const displayComparisonScore = comparisonMarks?.length > 0;

  const maxGradingScoreChangePerCriteria = GradingScoreUtil.getMaxGradingScoreChangePerCriterion(
    maxGradingScorePerEval,
    rubric
  );

  const isCreationScoreTable =
    tableType === STAR_RUBRIC_MARKS.OVERALL_CREATION_SCORE || tableType === STAR_RUBRIC_MARKS.STUDENT_CREATION_SCORE;

  const isGradingScoreTable = tableType === STAR_RUBRIC_MARKS.GRADING_SCORE;

  const mark = getMark({ criterionIndex, marks });
  const criterion = getCriterion({ criterionIndex, criteria });

  let scoreDiff = 0;
  if (scoreDiffs) {
    scoreDiff = scoreDiffs[criterionIndex];
  }

  const displayScoreYouGaveIcon =
    Boolean(scoreDiffs) || (isCalibrationActivity && isInstructor) || (displayComparisonScore && isCreationScoreTable);

  const displayComparisonIcon = Boolean(scoreDiffs) || (isCalibrationActivity && isInstructor) || isCreationScoreTable;

  return (
    <tr
      className="score-display__main-row"
      key={`star-mark-${criterionIndex}`}
      data-testid={`evaluation-score-criteria-${criterionIndex}`}
    >
      <td className="score-display__criteria-title">
        <CriterionInfo rubric={rubric} criterionIndex={criterionIndex} marks={marks} />
      </td>
      <td className="score-display__criteria-weight">{criterion.weight}</td>
      <td className="score-display__criteria-score">
        <ScoreRating
          displayScoreYouGaveIcon={displayScoreYouGaveIcon}
          isInstructor={isInstructor}
          mark={mark}
          criterionIndex={criterionIndex}
          grid={grid}
          precision={precision}
          isGradingScoreTable={isGradingScoreTable}
          type={type}
        />
        {displayComparisonScore && (
          <ComparisonScoresRating
            displayComparisonIcon={displayComparisonIcon}
            criterionIndex={criterionIndex}
            grid={grid}
            precision={precision}
            comparisonMarks={comparisonMarks}
            type={type}
          />
        )}
      </td>
      {showGradingScore && (
        <td className="score-display__criteria-level">
          <ScoreChangePerCriteria
            scoreDiff={scoreDiff}
            criterionIndex={criterionIndex}
            mark={mark}
            rubric={rubric}
            maxScoreChangePerCriteria={maxGradingScoreChangePerCriteria[criterionIndex]}
            comparisonMarks={
              comparisonMarks.find((comparisonMarksObject) => comparisonMarksObject.type === 'final-score')?.marks
            }
            isInstructor={isInstructor}
            type={type}
          />
        </td>
      )}
      {!isGradingScoreTable && showExactScore && (
        <td>
          <div>
            {isPercentage && (isInstructor || activity?.startingScore > 0 || startingScore > 0) ? (
              <ExactPercentageScore
                mark={mark}
                rubric={rubric}
                criterion={criterion}
                criterionIndex={criterionIndex}
                isComparison={false}
                criteriaModeScoringOverruled={criteriaModeScoringOverruled}
                type={type}
                activity={activity}
                startingScore={startingScore}
              />
            ) : (
              <ExactScore
                mark={mark}
                rubric={rubric}
                criterion={criterion}
                criterionIndex={criterionIndex}
                isComparison={false}
                criteriaModeScoringOverruled={criteriaModeScoringOverruled}
                type={type}
              />
            )}
          </div>
          {displayComparisonScore && showExactScore && !isGradingScoreTable && (
            <ComparisonScores
              comparisonMarks={comparisonMarks}
              criterion={criterion}
              criterionIndex={criterionIndex}
              isPercentage={isPercentage}
              isInstructor={isInstructor}
              criteriaModeScoringOverruled={criteriaModeScoringOverruled}
              type={type}
              rubric={rubric}
              activity={activity}
            />
          )}
        </td>
      )}
    </tr>
  );
};

type ExactScoreProps = {
  criteriaModeScoringOverruled: any[];
  rubric: Rubric;
  mark: any;
  criterion: any;
  criterionIndex: number;
  isComparison: boolean;
  type: string;
};

const ExactScore = ({
  criteriaModeScoringOverruled,
  rubric,
  mark,
  criterion,
  criterionIndex,
  isComparison,
  type,
}: ExactScoreProps) => {
  const { colorClass, inverseColorClass } = useColorClass(type);
  const scoreColor = isComparison ? inverseColorClass : colorClass;
  let exactScore = mark;
  if (mark > 0) {
    exactScore = calcWeightedScoreFromMark(mark, criterionIndex, rubric).toFixed(2);
  }

  if (!mark && mark !== 0) {
    exactScore = '-';
  }
  return (
    <div className={`score-display__score-value ${scoreColor}`}>
      <span>{exactScore}</span>
      <span className="score-display__score-weigth">{` / ${criterion.weight} ${localize({ message: 'Activity.Weight.MultiplePoints' })}`}</span>
      <div>
        {criteriaModeScoringOverruled && criterion.useMode && (
          <ModeSymbol criterionIndex={criterionIndex} criteriaModeScoringOverruled={criteriaModeScoringOverruled} />
        )}
      </div>
    </div>
  );
};

type ExactPercentageScoreProps = {
  criteriaModeScoringOverruled: any[];
  rubric: Rubric;
  mark: any;
  criterion: any;
  criterionIndex: number;
  isComparison: boolean;
  activity: Activity;
  type: string;
  startingScore?: number;
};

const ExactPercentageScore = ({
  criteriaModeScoringOverruled,
  rubric,
  mark,
  criterion,
  criterionIndex,
  isComparison,
  activity,
  type,
  startingScore,
}: ExactPercentageScoreProps) => {
  const { colorClass, inverseColorClass } = useColorClass(type);
  const scoreColor = isComparison ? inverseColorClass : colorClass;
  let exactScore = mark;

  if (mark > 0) {
    exactScore = CreationScoreUtil.scaleMark({
      mark,
      levelsInCriteria: getLevelsInCriteria(rubric, criterionIndex),
      startingScore: activity?.startingScore || startingScore || 0,
    });
  }

  if (!mark && mark !== 0) {
    exactScore = '-';
  }
  return (
    <div className={`score-display__score-value ${scoreColor}`}>
      <span tabIndex={0}> {exactScore === '-' ? '-' : simplePercent(round(exactScore))}</span>
      <div>
        {criteriaModeScoringOverruled && criterion.useMode && (
          <ModeSymbol criterionIndex={criterionIndex} criteriaModeScoringOverruled={criteriaModeScoringOverruled} />
        )}
      </div>
    </div>
  );
};

type ModeSymbolProps = {
  criterionIndex: number;
  criteriaModeScoringOverruled: any[];
};

type ModeLabelProps = {
  className?: string;
  testid?: string;
  title: string;
  style: string;
  type: string;
  location: 'bottom' | 'left' | 'right' | 'top';
};

const ModeSymbol = ({ criterionIndex, criteriaModeScoringOverruled }: ModeSymbolProps) => {
  const isOverruledMode = criteriaModeScoringOverruled[criterionIndex];

  const labelProps: ModeLabelProps = {
    style: 'normal',
    type: isOverruledMode === 'OverruledModeScoring' ? 'danger' : 'information',
    location: 'top',
    title:
      isOverruledMode === 'OverruledModeScoring'
        ? localize({ message: 'Rubric.Mode.Tooltip.Overruled' })
        : localize({ message: 'Rubric.Mode.Tooltip.Normal' }),
  };

  return <ModeLabel {...labelProps} />;
};

const mapStateToProps = (state: any) => {
  const course = getCourse(state);
  return {
    course,
    activity: getAssignment(state),
    isInstructor: course?.userRole === 'instructor',
  };
};

StarRubricMarks.defaultProps = {
  precision: 0.1,
  showExactScore: true,
  comparisonMarks: [],
};

export default connect(mapStateToProps)(StarRubricMarks);

const ComparisonScoresRating = ({ comparisonMarks, criterionIndex, displayComparisonIcon, type, grid, precision }) => {
  return comparisonMarks.map((comparisonObject, index) => {
    const comparisonMark = comparisonObject.marks[criterionIndex];
    return (
      <ComparisonScoreRating
        displayComparisonIcon={displayComparisonIcon}
        comparisonMark={comparisonMark}
        comparisonType={comparisonObject.type}
        criterionIndex={criterionIndex}
        grid={grid}
        precision={precision}
        key={`${comparisonObject.type}-${index}`}
        type={type}
      />
    );
  });
};

const ComparisonScoreRating = ({
  displayComparisonIcon,
  comparisonMark,
  criterionIndex,
  grid,
  precision,
  comparisonType,
  type,
}) => {
  const { inverseColorClass } = useColorClass(type);
  return (
    <div className="score-display__star-column">
      {displayComparisonIcon && (
        <div className="score-display__score-description">
          <ComparisonIcon comparisonType={comparisonType} />
        </div>
      )}
      <span style={{ marginRight: '1rem' }}>
        <Rating
          max={1}
          icon={<ClearRoundedIcon />}
          value={comparisonMark === 0 ? 1 : 0}
          className={`score-display__stars ${inverseColorClass}`}
          readOnly
        />
      </span>
      <Rating
        name={`comparison-grade-${criterionIndex}`}
        max={grid[criterionIndex].length - 1}
        value={comparisonMark === 0 ? 0 : comparisonMark}
        precision={precision}
        className={`score-display__stars ${inverseColorClass}`}
        readOnly
        icon={<RatingIcon level={grid[criterionIndex]} />}
      />
    </div>
  );
};

const ComparisonIcon = ({ comparisonType }) => {
  switch (comparisonType) {
    case 'class-average':
      return (
        <Tooltip title={<TranslatedText i18nKey="StarRubricMark.Icon.Label.ClassAverage" />} placement="top">
          <div>
            <i className="fa fa-bar-chart final-score-icon" />
            <span className="sr-only">
              <TranslatedText i18nKey="StarRubricMark.Icon.Label.ClassAverage" />
            </span>
          </div>
        </Tooltip>
      );
    case 'final-score':
      return (
        <Tooltip title={<TranslatedText i18nKey="StarRubricMark.Icon.Label.CreationFinalScore" />} placement="top">
          <div>
            <i className="fa fa-clipboard-check final-score-icon" />
            <span className="sr-only">
              <TranslatedText i18nKey="StarRubricMark.Icon.Label.CreationFinalScore" />
            </span>
          </div>
        </Tooltip>
      );
    case 'ai-score':
      return (
        <Tooltip title={<TranslatedText i18nKey="StarRubricMark.Icon.Label.AIScore" />} placement="top">
          <div>
            <i className="fa fa-magic final-score-icon" />
            <span className="sr-only">
              <TranslatedText i18nKey="StarRubricMark.Icon.Label.AIScore" />
            </span>
          </div>
        </Tooltip>
      );
    default:
      return null;
  }
};

type GetMarkArgs = {
  criterionIndex: number;
  marks: any[];
};

const getMark = ({ criterionIndex, marks }: GetMarkArgs) => {
  let mark = null;
  if (marks) {
    mark = marks[criterionIndex];
  }
  return parseFloat(mark);
};

type GetCriterionArgs = {
  criteria: any;
  criterionIndex: number;
};

export const getCriterion = ({ criteria, criterionIndex }: GetCriterionArgs) => {
  return criteria[criterionIndex];
};

type GetLevelLabelArgs = {
  marks: any[];
  levels: string[];
  levelIndex: number;
};

const getLevelLabel = ({ marks, levels, levelIndex }: GetLevelLabelArgs): string => {
  if (marks && marks.length > 0) {
    return levels[levelIndex];
  }
  return '';
};

type RatingIconProps = {
  level: any;
};

const RatingIcon = ({ level }: RatingIconProps) => {
  if (isRowBinary(level)) {
    return <CheckRoundedIcon />;
  }
  return <GradeRoundedIcon />;
};

export const CriterionInfo = ({ marks, rubric, criterionIndex }) => {
  const { criteria, levels, grid } = rubric;
  const mark = getMark({ criterionIndex, marks });
  const criterion = getCriterion({ criterionIndex, criteria });
  const levelIndex = Math.round(mark) <= levels.length - 1 ? Math.round(mark) : levels.length - 1;
  const levelLabel = getLevelLabel({ marks, levels, levelIndex });
  const hasLevelDescription = Boolean(grid[criterionIndex][Math.round(mark)]);
  const hasLevelLabel = Boolean(levelLabel);
  return (
    <>
      <ShortenText label={criterion.name} maxLength={50} />
      {hasLevelLabel && hasLevelDescription && (
        <div>
          <span className="score-display__label">
            <b>{levelLabel}</b>
            {hasLevelDescription && ` - ${grid[criterionIndex][Math.round(mark)]}`}
          </span>
        </div>
      )}
    </>
  );
};

const ComparisonScores = ({
  comparisonMarks,
  criterion,
  criterionIndex,
  isPercentage,
  isInstructor,
  criteriaModeScoringOverruled,
  rubric,
  activity,
  type,
}) => {
  return comparisonMarks.map((comparisonMarkObject, index) => {
    const comparisonMark = comparisonMarkObject.marks[criterionIndex];
    return (
      <div style={{ marginTop: 10 }}>
        {isPercentage && isInstructor ? (
          <ExactPercentageScore
            criterion={criterion}
            mark={comparisonMark}
            criterionIndex={criterionIndex}
            isComparison={true}
            criteriaModeScoringOverruled={criteriaModeScoringOverruled}
            rubric={rubric}
            activity={activity}
            key={`${comparisonMarkObject.type}-${index}`}
            type={type}
          />
        ) : (
          <ExactScore
            criterion={criterion}
            mark={comparisonMark}
            criterionIndex={criterionIndex}
            isComparison={true}
            criteriaModeScoringOverruled={criteriaModeScoringOverruled}
            rubric={rubric}
            key={`${comparisonMarkObject.type}-${index}`}
            type={type}
          />
        )}
      </div>
    );
  });
};

const ScoreRating = ({
  displayScoreYouGaveIcon,
  isInstructor,
  mark,
  criterionIndex,
  grid,
  precision,
  isGradingScoreTable,
  type,
}) => {
  const { colorClass } = useColorClass(type);
  const i18nKey = isInstructor
    ? isGradingScoreTable
      ? 'StarRubricMark.Icon.Label.ScoreStudentGave'
      : 'StarRubricMark.Icon.Label.ScoreStudentReceived'
    : 'StarRubricMark.Icon.Label.ScoreYouGave';

  const isBinary = grid[criterionIndex].length === 2;
  return (
    <div
      className="score-display__star-column"
      role="img"
      aria-label={localize({
        message:
          mark === null || isNaN(mark)
            ? 'StarRubricMark.Stars.Ungraded'
            : isBinary
              ? mark === 0
                ? 'StarRubricMark.Stars.Binary.0'
                : 'StarRubricMark.Stars.Binary.1'
              : 'StarRubricMark.Stars.WithMark',
        options: {
          count: mark,
        },
      })}
    >
      <div className="score-display__score-description">
        {displayScoreYouGaveIcon && (
          <Tooltip title={<TranslatedText i18nKey={i18nKey} />} placement="top">
            <div>
              <i className="fa fa-user-graduate student-score-icon" />
              <span className="sr-only">
                <TranslatedText i18nKey={i18nKey} />
              </span>
            </div>
          </Tooltip>
        )}
      </div>
      <span style={{ marginRight: '1rem' }}>
        <Rating
          max={1}
          icon={<ClearRoundedIcon />}
          value={mark === 0 ? 1 : 0}
          className={`score-display__stars ${colorClass}`}
          readOnly
          aria-hidden="true"
          role={undefined}
          aria-label={undefined}
        />
      </span>
      <Rating
        name={`primary-grade-${criterionIndex}`}
        max={grid[criterionIndex].length - 1}
        value={mark === 0 ? 0 : mark}
        precision={precision}
        className={`score-display__stars ${colorClass}`}
        readOnly
        icon={<RatingIcon level={grid[criterionIndex]} />}
        aria-hidden="true"
        role={undefined}
        aria-label={undefined}
      />
    </div>
  );
};
