export const SORT_TYPE = {
  NAME: 'NAME',
  CREATE: 'CREATE',
  FEEDBACK_RECEIVED: 'FEEDBACK_RECEIVED',
  FEEDBACK_GIVEN: 'FEEDBACK_GIVEN',
  EVALUATION_RECEIVED: 'EVALUATION_RECEIVED',
  EVALUATION_GIVEN: 'EVALUATION_GIVEN',
  ISSUES: 'ISSUES',
  CREATION_SCORE: 'CREATION_SCORE',
  EVALUATION_SCORE: 'EVALUATION_SCORE',
  FEEDBACK_SCORE: 'FEEDBACK_SCORE',
  OVERALL_SCORE: 'OVERALL_SCORE',
  DISPUTED_DATE: 'DISPUTE_DATE',
  RESOLVED_DATE: 'RESOLVED_DATE',
  STATUS: 'STATUS',
  GROUP: 'GROUP',
};

export const SORT_ORDER = {
  ASCENDING: 1,
  DESCENDING: -1,
};
