import { Activity, Course, Creation } from '@kritik/types.generated';
import { isGroupPresentationActivity } from '@kritik/utils/activity';
import { getAssignments, navigateToInstructorCreationView } from 'actions/activity';
import { getCourse as fetchCourse } from 'actions/courses';
import getPaginatedResults from 'actions/pagination/actions';
import { selectAssignment, selectCourse, selectSubmission } from 'actions/select';
import { getStudentsByCourse } from 'actions/students';
import { UserRole } from 'app-types';
import PaginationMenu from 'components/CreationList/PaginationMenu';
import PageContainer from 'components/layout/PageContainer';
import SubmissionList from 'containers/assignments/creations/List';
import SubmissionView from 'containers/assignments/creations/View';
import { Component } from 'react';
import { connect } from 'react-redux';
import { selectStudent } from 'reducers/selected';
import { listSpotlights } from 'redux/spotlight';
import { getAssignment } from 'selectors/activity';
import { getCourse } from 'selectors/course';
import { RouterProp, withRouter } from 'utils/withRouter';

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
    course: getCourse(state),
    creations: state.entities.user_assignments,
    user: state.user,
    selectedStudentId: state.selected.studentId,
  };
};

type CreationOverviewState = {
  loaded: boolean;
};

type CreationOverviewProps = {
  activity: Activity;
  course: Course & { userRole: UserRole };
  creations: { [key: string]: Creation };
  fetchCourse: ({ id }: { id: string }) => void;
  getAssignments: ({ courseId }: { courseId: string }) => void;
  getAssignmentSubmissions: () => void;
  getPaginatedResults: (activityId: string, creationId: string) => void;
  getStudentsByCourse: ({ courseId }: { courseId: string }) => void;
  listSpotlights: ({ activity }: { activity: string }) => void;
  navigateToInstructorCreationView: (params: {
    assignmentId: string;
    courseId: string;
    creationId: string;
    groupView: boolean;
  }) => void;
  router: RouterProp;
  selectAssignment: (activityId: string) => void;
  selectCourse: (courseId: string) => void;
  selectSubmission: (creationId: string) => void;
  selectStudent: (studentId: string) => void;
  selectedStudentId: string;
};

class CreationOverview extends Component<CreationOverviewProps, CreationOverviewState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  async componentDidMount() {
    const { activity, creations } = this.props;
    const { creationId } = this.props.router.params;

    if (!activity || activity.numSubmissions !== Object.keys(creations).length) {
      const {
        getAssignments,
        getPaginatedResults,
        getStudentsByCourse,
        fetchCourse,
        listSpotlights,
        selectAssignment,
        selectCourse,
        selectSubmission,
        selectStudent,
        router,
      } = this.props;

      await Promise.all([
        fetchCourse({ id: router.params.courseId }),
        getAssignments({ courseId: router.params.courseId }),
        getStudentsByCourse({ courseId: router.params.courseId }),
        getPaginatedResults(router.params.assignmentId, router.params.creationId),
        selectAssignment(router.params.assignmentId),
        selectCourse(router.params.courseId),
        selectSubmission(router.params.creationId),
        listSpotlights({ activity: router.params.assignmentId }),
      ]);
      const selectedCreation = this.props.creations[router.params.creationId];
      if (!this.state.loaded && selectedCreation) {
        if (!this.props.selectedStudentId) {
          selectStudent(selectedCreation.student as string);
        }
        this.setState({ loaded: true });
      }
    } else if (!this.state.loaded && this.props.creations[this.props.router.params.creationId]) {
      if (!this.props.selectedStudentId) {
        const selectedCreation = this.props.creations[this.props.router.params.creationId];
        if (selectedCreation) {
          selectStudent(selectedCreation.student);
        }
      }
      this.setState({ loaded: true });
    }
    if (creationId) {
      this.props.selectSubmission(creationId);
      const selectedCreation = this.props.creations[this.props.router.params.creationId];
      if (selectedCreation) {
        this.props.selectStudent(selectedCreation.student as string);
      }
    }
  }

  onSelect(submissionId: any, studentId: any, isGroupItem: any) {
    const { selectSubmission, selectStudent, router } = this.props;

    selectSubmission(submissionId);
    selectStudent(studentId);

    router.push(
      `/course/${router.params.courseId}/assignment/${router.params.assignmentId}/creation/${submissionId}?groupView=${!!isGroupItem}&${new URLSearchParams(router.location.query).toString()}`
    );

    window.scrollTo(0, 0);
  }

  render() {
    const { activity, course } = this.props;
    if (!activity || !this.state.loaded) {
      return null;
    }
    if (!(course.userRole === 'instructor')) {
      window.location.replace('/');
    }
    return (
      <PageContainer>
        <div className="submission-dashboard">
          <h1 className="visually-hidden">Submission Overview Page</h1>
          <div className="list-container">
            <h2 className="visually-hidden">Students List</h2>
            <div className="fixed-menu">
              <SubmissionList
                onSelect={(submissionId: string, studentId: string, isGroupItem: boolean) => {
                  return this.onSelect(submissionId, studentId, isGroupItem);
                }}
                dashboardView
                sortable
              />
              {!isGroupPresentationActivity(activity) && <PaginationMenu hidePageNumbers />}
            </div>
          </div>
          <SubmissionView />
        </div>
      </PageContainer>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, {
    fetchCourse,
    getAssignments,
    getPaginatedResults,
    getStudentsByCourse,
    listSpotlights,
    navigateToInstructorCreationView,
    selectAssignment,
    selectCourse,
    selectSubmission,
    selectStudent,
  })(CreationOverview)
);
