import { Course, User } from '@kritik/types.generated';
import { isEdlinkLmsSetup } from '@kritik/utils/course';
import { syncGrades } from 'actions/edlink';
import { AuthUser } from 'app-types';
import GradesDownloadActions from 'components/LmsGradeSync/GradesDownloadActions';
import LmsTable from 'components/LmsGradeSync/LmsTable';
import ScoreSyncBreakdownSwitch from 'components/LmsGradeSync/ScoreSyncBreakdown';
import { GRADE_SYNC_TYPE } from 'components/LmsGradeSync/constants';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import InlineInformation from 'components/layout/InlineInformation';
import { localize } from 'locales';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { selectDefaultLineItem } from 'redux/lms';
import { push } from 'router';
import { selectCurrentCourse } from 'selectors/course';
import { getBase64StringFromObject } from 'utils/general';
import { trackEvent } from 'utils/userEvents';
import { RouterProp, withRouter } from 'utils/withRouter';

type AuthenticateViaCanvasProps = {
  course: Course & { user: User };
  authUser: AuthUser;
};

function AuthenticateViaCanvas(props: AuthenticateViaCanvasProps) {
  if (!props.course) {
    return null;
  }
  const isCourseOwner = props.course.user._id === props.authUser._id;
  if (!isCourseOwner) {
    return null;
  }

  const canUseLmsApi = props.course?.lms?.canUseLmsApi;
  const isRefreshTokenAvailableForLms = props.course?.lms?.isRefreshTokenAvailableForLms;

  if (!canUseLmsApi || isRefreshTokenAvailableForLms) {
    return null;
  }

  const handleAuthorizeLms = () => {
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
    const baseUrl = App.config.get('baseUrl');
    const redirectState = {
      userId: props.authUser._id,
      courseId: props.course._id,
      redirectSource: 'grade-sync',
    };
    window.location.href = `${props.course.lms.redirectUrl}/login/oauth2/auth?client_id=${
      props.course.lms.lmsClientId
    }&state=${getBase64StringFromObject(redirectState)}&redirect_uri=${baseUrl}/api/v1/lms/oauth&response_type=code`;
  };

  return (
    <Button type="primary" onClick={handleAuthorizeLms} testid="authenticate-via-canvas">
      <TranslatedText i18nKey="GradeSync.AuthenticateViaCanvas.Btn.Title" />
      <i className="fa fa-external-link" aria-hidden="true" style={{ marginLeft: 8 }}></i>
    </Button>
  );
}

type LmsGradeSyncProps = {
  course: Course & { syncGradesBy: string } & { user: User };
  defaultLineItem: any;
  selectedCourseId: string;
  authUser: any;
  syncGrades: any;
  push: any;
  router: RouterProp;
};
function LmsGradeSync(props: LmsGradeSyncProps) {
  if (!props.course) {
    return null;
  }
  const [searchParams] = useSearchParams();
  const isNewIntegration = props.course && isEdlinkLmsSetup(props.course);
  const [selectedColumn, setSelectedColumn] = useState(props.defaultLineItem);
  const [gradeSyncType, setGradeSyncType] = useState(
    searchParams.get('syncBy') || props.course.syncGradesBy || GRADE_SYNC_TYPE.OVERALL
  );
  const [activities, setActivities] = useState([]);
  const [lmsColumns, setLmsColumns] = useState([]);
  const [activitiesToSync, setActivitiesToSync] = useState([]);
  const [validationError, setValidationError] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [isSyncingGrades, setIsSyncingGrades] = useState(false);
  const [syncError, setSyncError] = useState(null);

  const canvasAuthorizeResult = new URLSearchParams(window.location.search).get('canvasAuthorizeResult');

  if (canvasAuthorizeResult === 'fail') {
    enqueueSnackbar(localize({ message: 'GradeSync.CanvasAuthorize.Notice.Error' }), {
      title: localize({ message: 'GradeSync.CanvasAuthorize.Notice.Title' }),
      variant: 'error',
      testid: 'grade-sync-fail-snackbar',
      autoHideDuration: 10000,
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('canvasAuthorizeResult');
    window.history.replaceState({}, '', `${window.location.pathname}?${searchParams}`);
  } else if (canvasAuthorizeResult === 'success') {
    enqueueSnackbar(localize({ message: 'GradeSync.CanvasAuthorize.Notice.Success' }), {
      title: localize({ message: 'GradeSync.CanvasAuthorize.Notice.Title' }),
      variant: 'success',
      testid: 'grade-sync-success-snackbar',
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('canvasAuthorizeResult');
    window.history.replaceState({}, '', `${window.location.pathname}?${searchParams}`);
  }

  const handleGradeSyncTypeChange = () => {
    setSyncError(null);
    if (gradeSyncType === GRADE_SYNC_TYPE.OVERALL) {
      setGradeSyncType(GRADE_SYNC_TYPE.ACTIVITY);
    } else {
      setGradeSyncType(GRADE_SYNC_TYPE.OVERALL);
    }
  };

  useEffect(() => {
    if (isNewIntegration) {
      if (shouldRefresh) {
        setShouldRefresh(false);
      }
    }
  }, [shouldRefresh]);

  if (!isEdlinkLmsSetup(props.course)) {
    return props.router.push('/');
  }

  const canSyncActivities = (toSync: any) => {
    toSync.forEach((activityToSync: any) => {
      const result = activities.find((_activity) => {
        const { column: columnToSync } = activityToSync;
        const { id: columnIdToSync } = columnToSync;
        if (
          columnIdToSync === (_activity as any).column.id &&
          !columnToSync.hasOwnProperty('isNewColumn') &&
          (_activity as any).column.isNewColumn === false
        ) {
          return true;
        }
        return false;
      });
      if (result) {
        setValidationError({
          message: localize({ message: 'GradeSync.SameColumnError' }),
        });
      }
    });

    return true;
  };

  const handleSetActivities = (updatedActivities: any) => {
    setValidationError(null);
    setActivities(updatedActivities);
    const _activitiesToSync = updatedActivities.filter((_activity: any) => {
      return _activity.status === 'ready-to-sync';
    });
    setActivitiesToSync(_activitiesToSync);
    canSyncActivities(_activitiesToSync);
  };

  const renderValidationError = () => {
    if (!isNewIntegration) {
      return null;
    }
    if (!validationError || gradeSyncType === GRADE_SYNC_TYPE.OVERALL) {
      return null;
    }
    return (
      <InlineInformation type="danger" title="" information>
        {(validationError as any).message}
      </InlineInformation>
    );
  };

  const renderSyncError = () => {
    if (!isNewIntegration) {
      return null;
    }
    if (!syncError) {
      return null;
    }
    return (
      <InlineInformation type="danger" title="" information>
        {syncError}
      </InlineInformation>
    );
  };

  const handleSyncActivities = () => {
    if (!canSyncActivities(activitiesToSync)) {
      return;
    }
    if (!props.course?.lms?.connectedCourseId) {
      return setValidationError({
        message: localize({ message: 'GradeSync.LMSCourseIdNotDefined' }),
      });
    }
    setIsSyncingGrades(true);

    props
      .syncGrades({ edlinkCourseId: props.course.lms.connectedCourseId, data: activitiesToSync })
      .then((res: any) => {
        if (res.errors) {
          setIsSyncingGrades(false);
          setShouldRefresh(true);
          return setSyncError(localize({ message: 'GradeSync.ScoresSyncError' }));
        }
        setIsSyncingGrades(false);
        setShouldRefresh(true);

        trackEvent('Grades Synced', props.authUser, {
          instructorEmail: props.authUser.email,
          activityIds: activitiesToSync.join(','),
          courseId: props.selectedCourseId,
        });
      });
  };

  const shouldDisableSyncButton = () => {
    if (gradeSyncType === GRADE_SYNC_TYPE.ACTIVITY) {
      return activitiesToSync.length === 0 || validationError;
    }
    if (gradeSyncType === GRADE_SYNC_TYPE.OVERALL) {
      return !selectedColumn || activities.length === 0;
    }
    return false;
  };

  const handleSyncAllScores = () => {
    const data = {
      noBreakdown: true,
      column: selectedColumn,
      kritikCourseId: props.course._id,
    };
    setIsSyncingGrades(true);

    props.syncGrades({ edlinkCourseId: props.course.lms.connectedCourseId, data }).then((res: any) => {
      if (res.errors) {
        setIsSyncingGrades(false);
        setShouldRefresh(true);
        return setSyncError(localize({ message: 'GradeSync.ScoresSyncError' }));
      }
      setSelectedColumn(null);
      setIsSyncingGrades(false);
      setShouldRefresh(true);

      trackEvent('Grades Synced', props.authUser, {
        instructorEmail: props.authUser.email,
        courseId: props.selectedCourseId,
      });
    });
  };

  const getSyncFunction = () => {
    if (gradeSyncType === GRADE_SYNC_TYPE.ACTIVITY) {
      return handleSyncActivities;
    }
    return handleSyncAllScores;
  };

  const renderSyncButton = () => {
    if (isNewIntegration) {
      return (
        <Button
          type="primary"
          disabled={shouldDisableSyncButton()}
          unavailable={shouldDisableSyncButton()}
          onClick={getSyncFunction()}
          loading={isSyncingGrades}
          testid="sync-scores"
        >
          {gradeSyncType === GRADE_SYNC_TYPE.ACTIVITY
            ? localize({ message: 'GradeSync.SyncBtn.ByActivity.Title' })
            : localize({ message: 'GradeSync.SyncBtn.NoBreakdown.Title' })}
        </Button>
      );
    }
  };

  return (
    <div className="page-content-container">
      <div className="page-content">
        <div className="lms-grade-sync__title-container">
          <h2>
            <TranslatedText i18nKey="GradeSync.Page.Title" />
          </h2>
          <AuthenticateViaCanvas course={props.course} authUser={props.authUser} />
        </div>
        <GradesDownloadActions />
        <ScoreSyncBreakdownSwitch
          onChange={handleGradeSyncTypeChange}
          value={gradeSyncType}
          isNewIntegration={isNewIntegration}
        />
        <h4>
          <TranslatedText i18nKey="GradeSync.Table.Title" />
        </h4>
        <p>
          <TranslatedText i18nKey="GradeSync.Table.Notice" />
        </p>
        {isNewIntegration && (
          <InlineInformation type="information" title="" information style={{ marginBottom: '20px' }}>
            <TranslatedText
              i18nKey="GradeSync.Table.Weight.Information"
              values={{ weight: props.course.markingScheme.isPercentage ? '100' : '1' }}
            />
          </InlineInformation>
        )}
        <LmsTable
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          isNewIntegration={isNewIntegration}
          gradeSyncType={gradeSyncType}
          activities={activities}
          lmsColumns={lmsColumns}
          setActivities={handleSetActivities}
          setLmsColumns={setLmsColumns}
          onRefresh={shouldRefresh}
        />
        {renderValidationError()}
        {renderSyncError()}
        {renderSyncButton()}
      </div>
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    selectedCourseId: state.selected.courseId,
    course: selectCurrentCourse(state),
    defaultLineItem: selectDefaultLineItem(state),
    authUser: state.user.authUser,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    syncGrades,
    push,
  })(LmsGradeSync)
);
