import { Activity, Creation, CreationScore, Rubric, Student } from '@kritik/types.generated';
import * as ActivityUtils from '@kritik/utils/activity';
import { getLevelsInCriteria, getTotalCriteriaWeight, isCriterionBinary } from '@kritik/utils/rubric';
import { CreationScoreUtil } from './creation';
import { EvaluationScoreUtil } from './evaluation';
import { convertScoreToPercentage } from './general';

export class GradingScoreUtil {
  static MAX_CALIBRATION_GRADING_SCORE_CHANGE = 5000;

  static DEFAULT_MAX_GRADING_SCORE_CHANGE = 1100;

  static DEFAULT_GRADING_SCORE = 1500;

  static MIN_GRADING_SCORE = 1000;

  static MIN_GRADING_POWER = 1000; // min influence student has on a score

  static MAX_GRADING_POWER = 6000; // max influence student has on a score

  static getMaxScoreChangePerAssignment(isCalibrationActivity: boolean) {
    return isCalibrationActivity ? this.MAX_CALIBRATION_GRADING_SCORE_CHANGE : this.DEFAULT_MAX_GRADING_SCORE_CHANGE;
  }

  // TODO: get maxScoreChange from class constants instead of function param
  static calcScorePerActivity = (scoreChange: number, maxScoreChange: number, creation: Creation) => {
    if (creation && GradingScoreUtil.isGradingScoreScoredByTeacher(creation)) {
      return GradingScoreUtil.getTeacherGradingScore(creation);
    }
    return convertScoreToPercentage(scoreChange, maxScoreChange);
  };

  static calcScorePerEval = (scoreChange: number, maxScoreChange: number) => {
    return convertScoreToPercentage(scoreChange, maxScoreChange);
  };

  static calcScorePerCriterion = (scoreChange: number, maxScoreChange: number) => {
    return convertScoreToPercentage(scoreChange, maxScoreChange);
  };

  static getGradingSkillsChangeFromEval = (evaluation: CreationScore) => {
    if (!evaluation) {
      return 0;
    }
    return evaluation.gradingSkillsChange.reduce((acc, el) => {
      return acc + el;
    }, 0);
  };

  static getActivityGradingSkillsChange = ({
    creationsToEvaluate,
    maxGradingScoreChangePerActivity,
    studentId,
  }: {
    creationsToEvaluate: Creation[];
    maxGradingScoreChangePerActivity: number;
    studentId: string;
  }) => {
    const myEvaluations = [];
    creationsToEvaluate.forEach((creation) => {
      const evaluation = creation.scores.find((score) => {
        return ((score.student as Student)._id || (score.student as string)) === studentId;
      });
      if (evaluation) {
        myEvaluations.push(evaluation);
      }
    });

    let gradingSkillsChange = 0;
    myEvaluations.forEach((evaluation) => {
      if (evaluation) {
        gradingSkillsChange += evaluation.gradingSkillsChange.reduce((acc, el) => {
          return acc + el;
        }, 0);
      }
    });

    // account for missed evaluations
    const numEvaluationsMissed = creationsToEvaluate.length - myEvaluations.length;
    if (numEvaluationsMissed > 0) {
      const maxGradingScoreChangePerEval = maxGradingScoreChangePerActivity / creationsToEvaluate.length;
      gradingSkillsChange -= maxGradingScoreChangePerEval * numEvaluationsMissed;
    }

    return gradingSkillsChange;
  };

  static isGradingScoreScoredByTeacher(creation: Creation) {
    return creation.teacherGradingScore !== undefined && creation.teacherGradingScore !== null;
  }

  static getTeacherGradingScore(creation: Creation) {
    return creation.teacherGradingScore;
  }

  static getFraction = (mark: number) => {
    return ((mark * 10) % 10) / 10;
  };

  static isMarkInMiddle = (markToCompare: number) => {
    return this.getFraction(markToCompare) === 0.5;
  };

  static getRawMarkDelta = (mark: number, markToCompare: number) => {
    return Math.abs(mark - markToCompare);
  };

  static getMarkDelta = (mark: number, markToCompare: number, isBinary: boolean = false) => {
    if (isBinary) {
      // if the peer weighted average or teacher score is exactly in the middle
      // we give max grading score change to students that chose 0 and students that chose 1
      if (markToCompare === 0.5) {
        return 0;
      }

      // Otherwise, we round the binary weighted average to either O or 1
      // Students who gave the same mark as the rounded average get max grading score change
      const roundedBinaryMark = Math.round(markToCompare);
      if (mark === roundedBinaryMark) {
        return 0;
      }
      // The other students get the min grading score change
      return 1;
    }

    const markDelta = this.getRawMarkDelta(mark, markToCompare);
    if (this.getFraction(markToCompare) !== 0 && markDelta <= 0.25) {
      // Any student score that is within 0.25 stars of the final score (+0.25,-0.25 or =0.25)
      // will receive 100 %.
      return 0;
    }
    return markDelta;
  };

  static getMaxGradingScoreChangePerCriterion(maxGradingScoreChangePerEval: number, rubric: Rubric): number[] {
    const totalCriteriaWeight = getTotalCriteriaWeight(rubric);
    const weights: number[] = rubric.criteria.map((criteria) => criteria.weight);

    return weights.map((weight) => (weight / totalCriteriaWeight) * maxGradingScoreChangePerEval);
  }

  static getKSChange = ({
    submission,
    score,
    rubric,
    maxGradingScoreChangePerCriterion,
  }: {
    submission: Creation;
    score: CreationScore;
    rubric: Rubric;
    maxGradingScoreChangePerCriterion: number[];
  }): { netKSChange: number; gradingSkillsChange: number[] } => {
    let netKSChange = 0;
    const gradingSkillsChange = [];

    score.marks.forEach((mark: number, i: number) => {
      const isBinary = isCriterionBinary(rubric, i);
      const numOfcriterionLevels = getLevelsInCriteria(rubric, i);
      // KSChangeCoefficient is the number of levels in a criterion divided by 6
      // 6 is arbitrary and it shapes distribution of the KSChange and as result the % grade for the criterion
      const KSChangeCoefficient = numOfcriterionLevels / 6;
      let markKSChange = 0;
      // for each of an evaluator's marks:
      // find difference between the final grade and the evaluator's given score
      const actualGrades = CreationScoreUtil.getRawCreationScore(submission, rubric);
      const markDelta = this.getMarkDelta(mark, actualGrades[i], isBinary);
      if (markDelta > KSChangeCoefficient * 4 || EvaluationScoreUtil.isEvaluationRemoved(score)) {
        netKSChange -= maxGradingScoreChangePerCriterion[i];
        markKSChange -= maxGradingScoreChangePerCriterion[i];
      } else {
        const ksChange =
          ((maxGradingScoreChangePerCriterion[i] * -2) / (KSChangeCoefficient * 4)) * markDelta +
          maxGradingScoreChangePerCriterion[i];
        netKSChange += ksChange;
        markKSChange += ksChange;
      }
      gradingSkillsChange.push(markKSChange);
    });

    return { netKSChange, gradingSkillsChange };
  };

  static getGradingSkillsChange = (evaluations: CreationScore[]) => {
    let ksChange = 0;

    evaluations.forEach((score) => {
      ksChange += score.gradingSkillsChange.reduce((acc, el) => {
        return acc + el;
      }, 0);
    });

    return ksChange;
  };
  static getGradingPowerChangeForMark(
    mark: number,
    markToCompare: number,
    gradingPowerChangeCoefficient: number,
    maxGradingPowerChangePerCriterion: number,
    isEvaluationRemoved: boolean,
    isBinary?: boolean
  ) {
    const gradingPowerBoundary = gradingPowerChangeCoefficient * 4;
    const markDelta = GradingScoreUtil.getMarkDelta(mark, markToCompare, isBinary);

    if (markDelta > gradingPowerBoundary || isEvaluationRemoved) {
      return maxGradingPowerChangePerCriterion * -1;
    }
    return (
      ((maxGradingPowerChangePerCriterion * -2) / gradingPowerBoundary) * markDelta + maxGradingPowerChangePerCriterion
    );
  }

  static calculateWeightedAverageFromScores(creation: Creation, activity: Activity) {
    let evaluatorsTotalKS = 0;
    // holds total weighted marks for each criteria
    const marksList: number[] = [];
    const { scores } = creation;
    // Excludes removed and late excluded evaluations
    const filteredScores = scores.filter(
      (score: CommonEvaluation) =>
        !EvaluationScoreUtil.isEvaluationRemoved(score) && !EvaluationScoreUtil.isPendingOrRejectedLateEvaluation(score)
    );
    filteredScores.forEach((score) => {
      let currentEvaluatorsKS = 1;
      if (!ActivityUtils.isCalibrationActivity(activity) && !ActivityUtils.isWithinGroupActivity(activity)) {
        currentEvaluatorsKS = Math.max((score.student as Student).score, this.MIN_GRADING_POWER);
        currentEvaluatorsKS = Math.min(currentEvaluatorsKS, this.MAX_GRADING_POWER);
      }
      // map each mark (criteria) to a total weighted mark
      score.marks.forEach((mark: number, i: number) => {
        if (!marksList[i]) {
          marksList[i] = 0;
        }
        marksList[i] += currentEvaluatorsKS * mark;
      });
      evaluatorsTotalKS += currentEvaluatorsKS;
    });
    // create a new average scores grid to be saved on the creation
    const rawWeightedAverage: number[] = [];
    const roundWeightedAverage: number[] = [];
    if (ActivityUtils.isCalibrationActivity(activity)) {
      evaluatorsTotalKS = 1;
    }
    marksList.forEach((mark) => {
      const weightedAvg = mark / evaluatorsTotalKS;
      rawWeightedAverage.push(weightedAvg);
      roundWeightedAverage.push(Math.round(weightedAvg));
    });
    return { rawWeightedAverage, roundWeightedAverage };
  }
}
