import * as ActivityUtils from '@kritik/utils/activity';
import * as statusUtil from '@kritik/utils/stage';
import { finalizeAssignment, navigateToEditActivity } from 'actions/activity';
import { resetAsync } from 'actions/async';
import { setAccessibilityMessage } from 'actions/messages';
import { closeConfirmModal, closeGlobalModal, openConfirmModal, openGlobalModal } from 'actions/modals';
import { resetSubmission, selectSubmission } from 'actions/select';
import { getStudentsByCourse } from 'actions/students';
import AdvanceActivityStageModal from 'components/Activity/AdvanceActivityStageModal';
import RevertActivityModal from 'components/Activity/RevertActivityModal';
import { ActivityHeaderButtons } from 'components/Assignment/ActivityHeaderButtons';
import TableTitle from 'components/CreationList/Title';
import { PageHeader } from 'components/layout';
import NoticeBoard from 'components/layout/NoticeBoard';
import PageContainer from 'components/layout/PageContainer';
import { AssignmentOverview } from 'containers/assignments/overview';
import OverviewSection from 'containers/assignments/overview/Section';
import StudentParticipation from 'containers/assignments/overview/StudentParticipation';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router';
import ActivityActions from 'redux/activity/action';
import { getAssignment } from 'selectors/activity';
import { getCourse } from 'selectors/course';
import { getCreationsFromEntities } from 'selectors/creation';
import { GET_STUDENTS_BY_COURSE } from 'types';
import { withRouter } from 'utils/withRouter';

const ITEM_HEIGHT = 65;
const MAX_HEIGHT = 600;

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    entities: state.entities,
    editModal: false,
    modalStatus: state.modal,
    assignmentId: state.selected.assignmentId,
    assignment: getAssignment(state, state.selected.assignmentId),
    submissions: getCreationsFromEntities(state, state.selected.assignmentId),
    selectedSubmissionId: state.selected.submissionId,
    course: getCourse(state),
    getStudentsByCourseSuccess: state.async.GET_STUDENTS_BY_COURSE.success,
  };
};

type InstructorViewState = any;

class InstructorView extends Component<{}, InstructorViewState> {
  state = {
    editModal: false,
    revertActivityModalOpen: false,
    advanceActivityStageModalOpen: false,
  };

  componentDidMount() {
    (this.props as any).selectSubmission(null);
    (this.props as any).getStudentsByCourse({
      courseId: (this.props as any).router.params.courseId,
    });
    const urlParams = new URLSearchParams(document.location.search);
    if (urlParams.get('revert') === 'true') {
      this.setState({ revertActivityModalOpen: true });
    }
    if (urlParams.get('advance') === 'true') {
      this.setState({ advanceActivityStageModalOpen: true });
    }
  }

  componentWillUnmount() {
    (this.props as any).resetAsync(GET_STUDENTS_BY_COURSE);
  }

  toggleView() {
    (this.props as any).resetSubmission();
  }

  setIsFinalizing(isFinalizing: any) {
    this.setState({ isFinalizing });
  }

  scoreHasFOF(score: any) {
    return !(
      score.feedbackOnFeedback.comment === '' &&
      score.feedbackOnFeedback.critical === 0 &&
      score.feedbackOnFeedback.motivational === 0
    );
  }

  hasSchedule() {
    if (ActivityUtils.isCalibrationActivity((this.props as any).assignment)) {
      return statusUtil.getEvaluationStage((this.props as any).assignment).endDate;
    }
    return statusUtil.getCreationStage((this.props as any).assignment).endDate;
  }

  getHeight = (dataList: any) => {
    const calcHeight = dataList
      .map(() => {
        return ITEM_HEIGHT;
      })
      .reduce((a: any, b: any) => {
        return a + b;
      }, 0);
    return calcHeight > MAX_HEIGHT ? MAX_HEIGHT : calcHeight;
  };

  resetFinalizeWarning = () => {
    this.setState({ noGrades: false });
  };

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'assignment' does not exist on type 'Read... Remove this comment to see the full error message
    const { assignment, getStudentsByCourseSuccess, course } = this.props;
    if (!assignment || !getStudentsByCourseSuccess) {
      return null;
    }

    const { title } = assignment;

    return (
      <div>
        {(this.state as any).noGrades && (
          <NoticeBoard type="danger">
            One or more of your students did not receive any grades. Please manually edit their grades before
            finalizing.
          </NoticeBoard>
        )}
        <PageContainer>
          <PageHeader title={title} separator testid="activity-page__activity-title">
            <ActivityHeaderButtons
              assignment={assignment}
              submissions={(this.props as any).submissions}
              course={course}
              openRevertActivityModal={() => {
                this.setState({ revertActivityModalOpen: true });
              }}
              openAdvanceActivityStageModal={() => {
                this.setState({ advanceActivityStageModalOpen: true });
              }}
            />
          </PageHeader>
          <RevertActivityModal
            course={course}
            activity={assignment}
            open={this.state.revertActivityModalOpen}
            onClose={() => {
              this.setState({ revertActivityModalOpen: false });
            }}
          />
          <AdvanceActivityStageModal
            course={course}
            activity={assignment}
            open={this.state.advanceActivityStageModalOpen}
            onClose={() => {
              this.setState({ advanceActivityStageModalOpen: false });
            }}
          />
          <AssignmentOverview>
            <StudentTable activity={assignment} course={course} />
          </AssignmentOverview>
        </PageContainer>
      </div>
    );
  }
}

export const StudentTable = ({ activity, course }) => {
  if (ActivityUtils.isPresentationActivity(activity)) {
    if (statusUtil.isCreateOrLater(activity)) {
      return (
        <OverviewSection title={<TableTitle />} hideSeparator>
          <Outlet />
        </OverviewSection>
      );
    }
    return <StudentParticipation activity={activity} course={course} />;
  }

  if (statusUtil.isScheduled(activity)) {
    return (
      <OverviewSection title={<TableTitle />} hideSeparator>
        <Outlet />
      </OverviewSection>
    );
  }
  return null;
};

export default withRouter(
  connect(mapStateToProps, {
    setAccessibilityMessage,
    selectSubmission,
    resetSubmission,
    finalizeAssignment,
    getStudentsByCourse,
    navigateToEditActivity,
    openGlobalModal,
    closeGlobalModal,
    resetAsync,
    openFinalizedConfirmModal: ActivityActions.openFinalizedConfirmModal,
    openConfirmModal,
    closeConfirmModal,
  })(InstructorView)
);
