import { calcWeightedScoreFromMark, getLevelOfScore, getPrecision, isRowBinary } from '@kritik/utils/rubric';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import { Rating } from '@material-ui/lab';
import classNames from 'classnames';
import ShortenText from 'components/General/ShortenText';
import FormField from 'components/core/form/Field';
import FormSection from 'components/core/form/Section';
import FormFieldTitle from 'components/core/form/Title';
import round from 'lodash/round';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { CreationScoreUtil } from '@kritik/utils/grade';
import { openRubricDropdown, updateRubricHighlightedCriteria, updateRubricHighlightedLevel } from 'actions/rubrics';
import { TranslatedText } from 'components/TranslatedText';
import { useUserRoleInCourse } from 'hooks/course';
import { localize } from 'locales';

function useWeightedEvalRating(rubric: any, evalRating: any) {
  const weightedEvalRating = evalRating.map((mark: any, index: any) => {
    const weightedScore = calcWeightedScoreFromMark(mark, index, rubric);
    return round(weightedScore, 2);
  });
  return useState(weightedEvalRating);
}

function StarRatingComponent({ rubric, highlightedCriteria, creation, onChange, startingScore, ...props }: any) {
  const { isInstructorInCourse, isStudentInCourse } = useUserRoleInCourse();
  const { criteria, levels, grid } = rubric;
  const [evalRating, setEvalRating] = useState(props.evalRating || new Array(criteria.length).fill(0));
  const [hover, setHover] = useState([]);
  const [isSelected, setIsSelected] = useState([]);
  const [weightedEvalRating, setWeightedEvalRating] = useWeightedEvalRating(rubric, evalRating);

  const getCurrentGrade = () => {
    if (creation && isInstructorInCourse) {
      return CreationScoreUtil.getRawCreationScore(creation, rubric);
    }
    return evalRating;
  };

  const isReduxConnected = () => {
    return isStudentInCourse;
  };

  const setSelectedScore = (e: any, index: any, value: any) => {
    const newIsSelected = Array.from(isSelected);
    newIsSelected[index] = true;
    setIsSelected(newIsSelected);

    evalRating[index] = value;
    setEvalRating(evalRating);
    if (typeof onChange === 'function') {
      onChange(evalRating);
    }

    const weightedScore = calcWeightedScoreFromMark(value, index, rubric);
    weightedEvalRating[index] = round(weightedScore, 2);
    setWeightedEvalRating(weightedEvalRating);
  };

  const setHoverScore = (value: any, index: any) => {
    if (isReduxConnected()) {
      props.updateRubricHighlightedCriteria(index);
      props.updateRubricHighlightedLevel(Math.ceil(value));
    }
  };

  const selectRow = (index: any) => {
    if (isReduxConnected()) {
      props.updateRubricHighlightedCriteria(index);
      props.openRubricDropdown(true);
    }
  };

  const getLevelName = (index: any) => {
    if (hover[index] > -1) {
      return levels[Math.ceil(hover[index])];
    }
    const rawScore = evalRating[index];
    const levelIndex = getLevelOfScore(rawScore, index, rubric);
    return levels[levelIndex];
  };

  const getMaxStarWidth = () => {
    const STAR_WIDTH = 24;
    return `${(levels.length + 1) * STAR_WIDTH + 8}px`;
  };

  const scoreEditorScoreValueWrapperClass = classNames('score-editor__score-value-wrapper', {
    'w-30': isInstructorInCourse,
    'w-18': isStudentInCourse,
  });

  const maxStarWidth = getMaxStarWidth();

  function renderSeparator() {
    return (
      <tr className="score-editor__separator">
        <td colSpan={4} />
      </tr>
    );
  }

  const isSelectedRow = (index: any) => {
    if (isReduxConnected() && highlightedCriteria === index) {
      return `selected-row`;
    }
    return '';
  };

  const getRatingIcon = (level: any) => {
    if (isRowBinary(level)) {
      return <CheckRoundedIcon style={{ pointerEvents: 'auto' }} />;
    }
    return <GradeRoundedIcon style={{ pointerEvents: 'auto' }} />;
  };

  const creationUserId = creation ? creation.user._id : '';

  return (
    <FormSection hideSeparator>
      <FormFieldTitle size="lg" label={props.label || localize({ message: 'StarRating.EvaluationScore' })} />
      <FormField>
        <table className="score-editor">
          <thead className="visually-hidden">
            <tr>
              <th id="title">
                <TranslatedText i18nKey="StarRating.Title" />
              </th>
              <th id="grade">
                <TranslatedText i18nKey="StarRating.Grade" />
              </th>
              <th id="level">
                <TranslatedText i18nKey="StarRating.Level" />
              </th>
              <th id="value">
                <TranslatedText i18nKey="StarRating.Value" />
              </th>
            </tr>
          </thead>
          <tbody>
            {renderSeparator()}
            {criteria.map((criterion: any, i: number) => {
              return (
                <React.Fragment>
                  <tr
                    data-testid={`selected-row-${i}`}
                    key={i}
                    className={isSelectedRow(i)}
                    onClick={() => {
                      return selectRow(i);
                    }}
                  >
                    <td className="score-editor__criteria-title" tabIndex={0}>
                      <ShortenText label={criterion.name} maxLength={50} />
                    </td>
                    <td
                      className="score-editor__star-display"
                      style={{
                        width: maxStarWidth,
                        minWidth: maxStarWidth,
                      }}
                    >
                      <span style={{ marginRight: '8px' }}>
                        <Rating
                          max={1}
                          icon={<ClearRoundedIcon style={{ pointerEvents: 'auto' }} />}
                          value={evalRating[i] === 0 ? 1 : 0}
                          onChange={(e) => {
                            setSelectedScore(e, i, 0);
                          }}
                          onChangeActive={(e, value) => {
                            setHoverScore(value === -1 ? value : 0, i);
                          }}
                          className="score-editor__star-rating-zero"
                        />
                      </span>
                      <Rating
                        data-testid={`assign-criteria-rating-${i}`}
                        name={`primary-edit-grade-${creationUserId}-${i}`}
                        className="score-editor__star-rating"
                        max={grid[i].length - 1}
                        value={evalRating[i]}
                        precision={getPrecision(grid[i])}
                        onChange={(e, value) => {
                          return setSelectedScore(e, i, value);
                        }}
                        onChangeActive={(e, value) => {
                          hover[i] = value;
                          setHover(hover);
                          setHoverScore(value, i);
                        }}
                        icon={getRatingIcon(grid[i])}
                      />
                    </td>
                    <td className="score-editor__criteria-level">{getLevelName(i)}</td>
                    <td className={scoreEditorScoreValueWrapperClass} data-testid={`criteria-score-${i}`}>
                      {evalRating[i] !== null
                        ? CreationScoreUtil.scaleMark({
                            mark: evalRating[i],
                            levelsInCriteria: grid[i].length - 1,
                            startingScore,
                          }).toFixed(0) + '%'
                        : localize({ message: 'StarRating.None' })}
                    </td>
                  </tr>
                  {renderSeparator()}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </FormField>
    </FormSection>
  );
}

StarRatingComponent.defaultProps = {
  evalRating: undefined,
  label: '',
};

export const StarRating = connect(
  (state: any) => {
    return {
      highlightedCriteria: state.rubric.rubrics.highlightedRubricCriteria,
    };
  },
  {
    updateRubricHighlightedCriteria,
    updateRubricHighlightedLevel,
    openRubricDropdown,
  }
)(StarRatingComponent);
